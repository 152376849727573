import { api } from "./api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSummary: builder.query({
      query: () => `dashboard/summary`,
      subscribe: false,
      forceRefetch: true,
    }),
    getTargetCreator: builder.query({
      query: (params) =>
        `dashboard/target-creator?metric=${params.metric}&period=${params.period}`,
    }),
    getCreatorRank: builder.query({
      query: (params) =>
        `dashboard/creator-rank?rank_type=${params.rankType}&period=${params.period}&creator_type=${params.creatorType}`,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetSummaryQuery,
  useGetTargetCreatorQuery,
  useGetCreatorRankQuery,
} = dashboardApi;
