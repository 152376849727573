import React from "react";
import { Card, CardContent, Grid, Typography, CardHeader } from "@mui/material";

const CreatorProfileDetail = (props) => {
  const { data } = props;
  const profile = data?.data;

  if (profile === undefined) {
    return null;
  }
  return (
    <>
    <Card sx={{ borderRadius: "25px", mt: 5 }}>
        <CardHeader title="Basic Profile" className="detail-title" />
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Name
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Phone
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.phone}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Email
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.email}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Birthday
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.birthday}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                ID Card Number
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.id_card_num}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Address
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.address}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Gender
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.gender}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Country
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.country}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ borderRadius: "25px", mt: 5 }}>
        <CardHeader title="Page Information" className="detail-title" />
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Page Name
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.page_name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Page Username
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.page_username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Page Link
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600, color: "#1267FC" }}
                color="text.secondary"
                gutterBottom
              >
                {profile.page_link}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ borderRadius: "25px", mt: 5 }}>
        <CardHeader title="Contract Information" className="detail-title" />
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Contract Type
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.contract}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Type
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600 }}
                color="text.secondary"
                gutterBottom
              >
                {profile.creator_type}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Creator Share
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600, color: "#1267FC" }}
                color="text.secondary"
                gutterBottom
              >
                {profile.share}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Official Join Date
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: 600, color: "#1267FC" }}
                color="text.secondary"
                gutterBottom
              >
                {profile.contract_start_date}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export { CreatorProfileDetail };
