import React, { useState } from "react";
import TableOrganization from "components/TableOrganization";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import "./styles.scss";
import ContractPopup from "components/organization/contractPopup";
import {
  useGetContractAllQuery,
  useDeleteContractByIdsMutation,
  useAddNewContractMutation,
} from "api/contractApi";
import { Alerts } from "components/alerts";
import ConfirmDialog from './../../confirmDialog/index';

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "min_watch_hours",
    label: "Min Watch Hours",
  },
  {
    id: "min_duration_hours",
    label: "Min Duration Hours",
  },
  {
    id: "program_name",
    label: "Program",
  },
  {
    id: "description",
    label: "Description",
  },
];
const renderContractTableBody = (
  row,
  index,
  isSelected,
  handleClick,
  handleClickOpenDialog
) => {
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell
        padding="checkbox"
        onClick={(event) => handleClick(event, row.id)}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.code}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.min_watch_hours}</TableCell>
      <TableCell>{row.min_duration_hours}</TableCell>
      <TableCell>{row.program_name}</TableCell>
      <TableCell>{row.description}</TableCell>
      <TableCell align="left">
        <Link
          component="button"
          onClick={() => handleClickOpenDialog(row.id)}
          underline="none"
        >
          <Typography className="edit-link">Edit</Typography>
        </Link>
      </TableCell>
    </TableRow>
  );
};
const ContractData = () => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(0);
  const [ids, setIds] = useState([]);
  const [alert, setAlert] = React.useState("");
  const [deleteContractByIds, deleteResp] = useDeleteContractByIdsMutation();
  const [addNewContract, addResp] = useAddNewContractMutation();
  const contracts = useGetContractAllQuery(null, true);

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClickOpenDialogConfirm = (selected) => {
    setIds(selected);
    setOpenConfirm(true);
  };

  const actionDelete = async (selected) => {
    setOpenConfirm(false);
    setAlert();
    try {
      const response = await deleteContractByIds(ids.join());
      if (response?.data.success) {
        setAlert(<Alerts msg={response?.data.message} type="success" />);
      }
    } catch (error) {
      setAlert(<Alerts msg={error} type="error" />);
    }
  };

  const submit = async (values) => {
    const response = await addNewContract(values).unwrap();
    if (response?.success) {
      setOpen(false);
      setAlert(<Alerts msg={response?.message} type="success" />);
    }
    try {
      // setOpen(false);
    } catch (error) {}
  };
  return (
    <>
      {(contracts?.isFetching || deleteResp?.isLoading) && <LinearProgress />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={contracts?.isFetching || deleteResp?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableOrganization
        headCells={headCells}
        data={contracts?.data}
        renderBody={renderContractTableBody}
        actionDelete={handleClickOpenDialogConfirm}
        actionClick={handleClickOpenDialog}
      />
      <ContractPopup
        open={open}
        setOpen={setOpen}
        onSubmit={submit}
        id={id}
        actionLoading={addResp.isLoading}
      />
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        onSubmit={() => actionDelete(ids)}
        dialogTitle="Delete Contract"
        dialogContent="Do you want to delete?"
      />
      {alert}
    </>
  );
};
export default ContractData;
