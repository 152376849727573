import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import { Step1, Step2, Step3, Step4 } from "components/editCreator";
import Icons from "constants/icons";
import {
  useUpdateCreatorMutation,
  useGetCreatorDetailQuery,
} from "api/creatorApi";
import "./styles.scss";
import { Alerts } from "components/alerts";
import EditCreatorForm from "./editCreatorForm";
import { Backdrop, CircularProgress } from "@mui/material";

const steps = [
  "Creator Profile",
  "Page Information",
  "Contract Information",
  "Billing Information",
];
let contentStep = "";

let EditCreator = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [addNewStatus, setAddNewStatus] = React.useState(0);
  const [btnNextText, setBtnNextText] = React.useState("Next");
  const [alert, setAlert] = React.useState("");
  const [updateCreator, updateResp] = useUpdateCreatorMutation();

  const { id } = useParams();
  const creatorDetail = useGetCreatorDetailQuery(id);

  const showCreatorList = () => {
    navigate(`/creator-management/creator-list`);
  };

  const submit = async (form) => {
    setAlert();
    try {
      if (activeStep >= steps.length) {
        const response = await updateCreator(form).unwrap();
        if (response.success) {
          setAddNewStatus(1);
          setAlert(<Alerts msg={response.message} type="success" />);
        } else {
          setAddNewStatus(2);
          setAlert(<Alerts msg={response.message} type="success" />);
        }
      }
    } catch (error) {
      setAddNewStatus(2);
      setAlert(<Alerts msg={error.data.error.message} type="error" />);
    }
  };

  React.useEffect(() => {
    if (activeStep === steps.length - 1) {
      setBtnNextText("Save");
    } else {
      setBtnNextText("Next");
    }
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setAddNewStatus(0);
  };

  if (activeStep === 0) {
    contentStep = <Step1 />;
  } else if (activeStep === 1) {
    contentStep = <Step2 />;
  } else if (activeStep === 2) {
    contentStep = <Step3 />;
  } else if (activeStep === 3) {
    contentStep = <Step4 />;
  }

  return addNewStatus === 0 ? (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={creatorDetail?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EditCreatorForm
        activeStep={activeStep}
        steps={steps}
        contentStep={contentStep}
        handleBack={handleBack}
        handleNext={handleNext}
        btnNextText={btnNextText}
        addResp={updateResp}
        onSubmit={submit}
        initialValues={
          creatorDetail?.data?.data.length === 0
            ? null
            : creatorDetail?.data?.data
        }
      />
      {alert}
    </>
  ) : (
    <Box sx={{ width: "100%", marginTop: "5vh" }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          padding: "68px",
        }}
      >
        <Typography component="span" className="notify-submit-form">
          {addNewStatus === 1 ? (
            <Icons.THUMB_UP_OUTLINED className="notify-ico" />
          ) : (
            <Icons.REPORT_GMAILERRORRED_OUTLINED className="notify-ico" />
          )}
        </Typography>
        {addNewStatus === 1 ? (
          <ListItemText
            primary="You’ve successfully submit your creator"
            secondary="Click the button below to see your edit creator and an updated list"
            className="notify-content"
          />
        ) : (
          <ListItemText
            primary="Oooppss Something Went Wrong"
            secondary="Click the button below to refresh the page"
            className="notify-content"
          />
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        {addNewStatus === 1 ? (
          <Button
            onClick={() => showCreatorList()}
            variant="contained"
            className="btn-back-to-creator-list"
          >
            Back to Creator List
          </Button>
        ) : (
          <Button
            onClick={handleReset}
            variant="contained"
            className="btn-back-to-creator-list"
          >
            Try Again
          </Button>
        )}
      </Paper>
    </Box>
  );
};
export default EditCreator;
