import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  TableRow,
  TableCell,
  CardContent,
  FormControl,
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import BaseTable from "components/TableCreator";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import {
  useGetRestremingCheckListQuery
  , useGetIndicatorListQuery
  , useUpdateRestreamStatusMutation
  , useGetRestremingCheckListSearchMutation
} from "api/restreamingCheckApi";
import { SingleSelect, DateRangePickerEx } from "components/filterSelect";
import queryString from "query-string";
import SearchComponent from "components/SearchComponent";
import { useGetCreatorTypeByProgramQuery } from "api/creatorTypeApi";
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import BugReportIcon from '@mui/icons-material/BugReport';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Alerts } from "components/alerts";
import { BootstrapTooltip } from "components/tooltip";
import { useGetCountryAllQuery } from "api/countryApi";
import axios from "api/apiAxios";
import SecondaryButton from "components/Button";
import moment from "moment";

const restreamCheckTableHeaders = [
  { label: "#", field: null },
  { label: "Page Name", field: "name" },
  { label: "Creator Type", field: "creator_type_id" },
  { label: "Contract Type", field: "contract_id" },
  { label: "Tier", field: "tier_id" },
  { label: "Video ID", field: "fb_video_id" },
  { label: "Published (UTC+7)", field: "published_at" },
  { label: "Video Length", field: "length" },
  { label: "Video Game", field: "game_name" },
  { label: "Verified Notes", field: "verifed_notes" },
  { label: "Actions", field: "" },
];

let params = {};

const RestreamingCheckList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterTypeChange, setFilterTypeChange] = React.useState(
    filter?.creator_type !== undefined ? filter?.creator_type : ""
  );
  const [isDataRefetch, setIsDataRefetch] = useState(false);

  const handleFilterTypeChange = (event) => {
    setFilterTypeChange(event.target.value);
  };

  const [indicator, setIndicator] = useState(0);

  const handleSelectIndicator = (event) => {
    setIndicator(event.target.value);
  };

  useEffect(() => {
    handleFilterSubmit();
  }, [indicator]);

  const handleFilterClear = () => {
    setFilterTypeChange("");
    setStartDate(null);
    setEndDate(null);
    setIndicator("");
    setCountry("");
    params = { };
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  };
  const handleFilterSubmit = () => {
    const filterUrl = {
      ...filter,
      creator_type: filterTypeChange,
      from: startDate,
      to: endDate,
      indicator: indicator,
      country_id: country,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    const url = queryString.stringify(filterUrl);
    navigate(`${location.pathname}?${url}`);
  };

  const creatorTypes = useGetCreatorTypeByProgramQuery({ program_id: 0 });
  const indicatorList = useGetIndicatorListQuery();
  const [updateRestreamStatus, updateResp] = useUpdateRestreamStatusMutation();

  useEffect(() => {
    params = queryString.parse(location.search);
    
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  }, [location.search]);

  const [alert, setAlert] = React.useState("");
  const [dialogTitle, setDialogTitle] = useState('');
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [saveVideoId, setSaveVideoId] = useState(0);

  const [country, setCountry] = useState(
    filter?.country_id !== undefined ? filter?.country_id : ""
  );
  const handleSelectCountry = (event) => {
    setCountry(event.target.value);
  };

  const actionButtonClick = (type, videoId) => {
    setAlert();
    setDialogTitle('Suspected');
    switch(type)
    {
      case 0:
        setDialogTitle('Suspected');
        break;
      case 1:
          setDialogTitle('Confirmed');
          break;
      case 2:
          setDialogTitle('Not Restream');
          break;
      case 3:
          setDialogTitle('Deleted');
          break;
    }
    setSaveVideoId(videoId);
    setOpenDialogConfirm(true);
  };

  const handleCloseDialog = async (type) =>
  {
    setOpenDialogConfirm(false);
    if(type == 'confirm')
    {
      try {
        let videoIndicator = 0;
        switch(dialogTitle)
        {
          case 'Suspected':
            videoIndicator = 0;
            break;
          case 'Confirmed':
            videoIndicator=1;
              break;
          case 'Not Restream':
            videoIndicator=2;
              break;
          case 'Deleted':
            videoIndicator=3;
              break;
        }
        const response = await updateRestreamStatus({id: saveVideoId, restream_indicator: videoIndicator, notes: notes}).unwrap();
        if (response.success) {
            setAlert(<Alerts msg="Update video indicator success" type="success" />);
            setIsDataRefetch(true);
        }
        else
        {
          setAlert(<Alerts msg={response.error} type="error" />);
        }
      } catch (error) {
        setAlert(<Alerts msg={error.data.error.message} type="error" />);
      }
    }
    setSaveVideoId(0);
    setDialogTitle('');
    setNotes('');
  };

  const [notes, setNotes] = useState('');
  const handleNoteChange = (event)=>
  {
    setNotes(event.target.value)
  };

  const renderRestreamingCheckTableBody = (data = []) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={restreamCheckTableHeaders.length}></TableCell>
        </TableRow>
      );

    return data.map((item, index) => {
      return (
        <TableRow
          hover
          className="hover-row"
          tabIndex={-1}
          key={index}
          //onClick={() => showDetail(item.id)}
        >
          <TableCell>{(1 - 1) * 5 + index + 1}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.creator_type_id}</TableCell>
          <TableCell>{item.contract_id}</TableCell>
          <TableCell>{item.tier_id}</TableCell>
          <TableCell><BootstrapTooltip title="Click to open video" placement="top">
              <Link
                href={
                  "https://www.facebook.com/"+
                  item.fb_video_id
                }
                underline="none"
                target="_blank"
              >
                {item.fb_video_id}
              </Link>
            </BootstrapTooltip></TableCell>
          <TableCell>{item.published_at}</TableCell>
          <TableCell>{item.length}</TableCell>
          <TableCell>{item.game_name}</TableCell>
          <TableCell>{item.verified_notes}</TableCell>
          <TableCell style={{ width: 320 }}><div>
              <Button variant="outlined" size="small" startIcon={<NearbyErrorIcon />} onClick={() => actionButtonClick(0, item.fb_video_id)}>Mark as Suspected Restream</Button>
            </div>
            <div>
              <Button variant="outlined" size="small" startIcon={<BugReportIcon />} onClick={() => actionButtonClick(1, item.fb_video_id)}>Mark as Confirmed Restream</Button>
            </div>
            <div>
              <Button variant="outlined" size="small" startIcon={<FavoriteBorderIcon />} onClick={() => actionButtonClick(2, item.fb_video_id)}>Mark as Not Restream</Button>
            </div>
            <div>
              <Button variant="outlined" size="small" startIcon={<DeleteForeverIcon />} onClick={() => actionButtonClick(3, item.fb_video_id)}>Mark as Deleted/Inactive</Button>
            </div></TableCell>
        </TableRow>
      );
    });
  };

  const tabStyle = {
    fontWeight: 600,
    textAlign: "center",
    flex: 1,
    maxWidth: "none",
    color: "#1267fc",
  };

  const [searchText, setSearchText] = useState(null);
  const onSearchChange = (value) =>{
    setSearchText(value.target.value);
  }

  const [SearchData, setSearchData] = useState(null);
  const [getSearchData, getSearchDataRes] = useGetRestremingCheckListSearchMutation();

  const handSearchClick = async () =>{
    setIsLoading(true);
    const dataSearch = await getSearchData({
      ...params, searchText
    }).unwrap();
    setSearchData(dataSearch);
    setIsLoading(false);
  }

  const countries = useGetCountryAllQuery();

  const exportData = () => {
    setIsLoading(true);
    const filterUrl = {
      ...filter,
      creator_type: filterTypeChange,
      from: startDate,
      to: endDate,
      indicator: indicator,
      country_id: country,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    axios({
      url: "/restreaming-check-export", //your url
      method: "GET",
      params: filterUrl,
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        let date = moment().format("YYYY-MM-DD");
        link.setAttribute("download", `restreaming_check_${date}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid container className="restreaming-check">
      <Grid item md={12}>
          <Card variant="outlined" sx={{ mb: 2, mt: 2, borderRadius: "25px" }}>
            <CardContent>
              <div className="filter-header">
                <span className="filter-title">Filter Data</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Period
                  </Typography>
                  <FormControl className="form-select">
                      <DateRangePickerEx
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Region
                  </Typography>
                  <SingleSelect
                    loading={countries?.isFetching}
                    data={countries?.data?.data}
                    labelName="Select Country"
                    labelId="lb_filter_country"
                    id="filter_country"
                    inputId="filter_country_input"
                    defaultValue={country}
                    handleChange={handleSelectCountry}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Creator Type
                  </Typography>
                  <FormControl className="form-select">
                    <SingleSelect
                      loading={creatorTypes?.isFetching}
                      data={creatorTypes?.data?.data}
                      labelName="Select Type"
                      labelId="lb_filter_type"
                      id="filter_type"
                      inputId="filter_type_input"
                      defaultValue={filterTypeChange}
                      handleChange={handleFilterTypeChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="btn-filter">
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-clear-filter"
                    onClick={handleFilterClear}
                  >
                    Clear
                  </Button>
                </FormControl>
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-submit-filter"
                    onClick={handleFilterSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Data table */}
        {alert}
        <Grid md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
              <div style={{ display: "flex" }} className="cp-table-content">
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      marginLeft: "19px",
                    }}
                  >
                    Creator List
                  </span>
                  <SecondaryButton
                    style={{ float: "right" }}
                    variant="contained"
                    className="btn-export"
                    onClick={() => exportData()}
                  >
                    Export Data
                  </SecondaryButton>
                  <SearchComponent handleClick={()=>handSearchClick()} onTextChange={(e)=>onSearchChange(e)} style={{ float: "right", height: "35px" }} />
                  <Select
                    style={{ float: "right", height: "35px" }}
                    className="summary-month"
                    value={indicator}
                    onChange={handleSelectIndicator}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {indicatorList?.data?.data.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
                <BaseTable
                    tableHeaders={restreamCheckTableHeaders}
                    renderBody={(data) => renderRestreamingCheckTableBody(data)}
                    filter={params}
                    serviceApi={useGetRestremingCheckListQuery}
                    searchData={SearchData}
                    isRefetch={isDataRefetch}
                    setIsRefetch={setIsDataRefetch}
                  />
                  <Dialog open={openDialogConfirm} onClose={()=>handleCloseDialog('close')}>
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {"Do you want to " + dialogTitle + " this video?"}
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Notes"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={notes}
                        onChange={handleNoteChange}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={()=>handleCloseDialog('close')}>Cancel</Button>
                      <Button onClick={()=>handleCloseDialog('confirm')}>Ok</Button>
                    </DialogActions>
              </Dialog>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={isLoading || updateResp?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default RestreamingCheckList;
