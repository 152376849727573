import { api } from "./api";

export const creatorTypeApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["CreatorTypes"],
  endpoints: (builder) => ({
    getCreatorTypeAll: builder.query({
      query: () => ({
          url: `creator-types`,
          method: 'GET',
          subscribe: false
      }),
      providesTags: ["CreatorTypes"],
    }),
    getCreatorTypeById: builder.query({
      query: (id) => ({
          url: `creator-type/${id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
      providesTags: ["CreatorTypes"],
    }),
    getCreatorTypeByProgram: builder.query({
      query: (params) => ({
          url: `creator-types/${params.program_id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
    }),
    addNewCreatorType: builder.mutation({
      query: (credentials) => ({
        url: "creator-type",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["CreatorTypes"],
    }),
    deleteCreatorTypeByIds: builder.mutation({
      query: (ids) => ({
        url: `creator-type/${ids}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CreatorTypes"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCreatorTypeAllQuery,
  useGetCreatorTypeByIdQuery,
  useGetCreatorTypeByProgramQuery,
  useAddNewCreatorTypeMutation,
  useDeleteCreatorTypeByIdsMutation,
} = creatorTypeApi;
