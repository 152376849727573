import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import './styles.scss';

const PolicyPage = () => {

  return (
      <div>
        <div class="navbar">
        <a href="about">About</a>
        <a href="sign-up">Register</a>
        <a href="sign-in">Home</a>
        </div>
        <div class='main'>
    <ThemeProvider>
      <Container>
      <title className="typography" component="h1" variant="h5">
          CMD - Privacy Policy
        </title>
        <meta name="description" content="This privacy policy helps you to understand how we handle your personal information and how you can update, delete your information."></meta>
        <div>
        <div>
        <h1> <a href="#privacy_policy">Privacy Policy</a></h1>
            <ul>
                <li><a href="#information_collection"> Information Collection</a></li>
                <li><a href="#disclosure"> Use, Protection, and Disclosure of Your Information</a></li>
                <li><a href="#rights"> Your rights over your personal information</a></li>
                <li><a href="#unsolicited">Unsolicited Information</a></li>
                <li><a href="#security"> Security and Retention</a></li>
                <li><a href="#links"> Links to Other Sites</a></li>
                <li><a href="#kids"> Children\'s Privacy</a></li>
                <li><a href="#changes"> Changes to This Privacy Policy</a></li>
                <li><a href="#condition">Condition of Use</a></li>
                <li><a href="#contact">Contact Us</a></li>
            </ul>
        </div>
    </div>
    <div>
        <div>
        <h2 id="privacy_policy">Privacy Policy</h2>
            <p>CMD, (Content Creator Management Dashboard, “we”, “us” or “our”) owns and operates the websites located at <a href="/"> https://csp.maingames.com/ </a>. We provide a platform where registered users can have access to a variety of activities, including to upload, download, generate, edit, display, browse, and promote gaming video highlights.</p>
            <p>We at CMD understand your need for online privacy as a visitor or users to our websites including CMD and/or any of its subdomains. Therefore we have created this Privacy Policy to inform website visitors and users regarding our policies with the collection, use, and disclosure of personal information if anyone decided to use our services on CMD.</p>
            <p>By visiting, registering and using our websites and services, you accept and agree to the collection and use of information in relation with this policy. The personal information that we collect is used for providing and improving your experience with our services. We shall not use or share your information with anyone except as described in this Privacy Policy.</p>

            <ul>
                <li id="information_collection">
                    <h2>Information Collection</h2>
                    <p>We collect and store any information you voluntarily provide on our websites or provide to us in any other way. The personal information is described below.</p>
                    <p><strong>Personally Identifiable Information (PII)</strong>: When you opt to sign up on CMD, we require certain information including your username, password and email address, which can be used to contact and identify you. Beyond the information collected during registration, you may provide additional information including but not limited to your first and last names, aliases, payment method(s), social media, video recordings of your gameplays or any other content that you create when using our services. This includes things such as emails, messages, photos or videos you provide to us. 
                    You may also give us permission to collect your information in other services when you connect your CMD account with other social networking services such as but not limited to Discord, Facebook, Instagram, Twitter and/or Twitch.
                    </p>
                    <p><strong>Non-Personally Identifiable Information (NPII)</strong>: NPII is information other than PII, including aggregate information derived from PII, that may allow for singling out individual behaviours (e.g., device ID, cookies, IP address). Such information includes what we record in our server logs, for instance, IP address, operating system, browser version, pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
                    <p>We do not collect sensitive information that reveals races or ethnicity, religion or philosophical beliefs, sexual orientation, political affiliation, trade union membership, genetic/biometric data, and medical data.</p>
                    <p>You have the right to edit, access, or transfer your personal information, or to delete your personal information if you are terminating your registration with us.</p>
                </li>
                <li id="disclosure">
                    <h2>Use, Protection, and Disclosure of Your Information</h2>
                    <p>Unless you voluntarily provide PII to us (e.g, by registering on CMD), we do not ask you nor do we collect such information from you. We may retain and use your personal information, PII or NPII separately, or together, in aggregated form. We shall not rent or sell your personal information to anyone and we only disclose your personal information as described below. In cases where the organization receiving your personal data may be located outside of your home country, they shall have to provide us with adequate safeguards.</p>
                    <p><strong>CMD Personnel</strong>: CMD employees and authorized consultants and/or contractors may have access to user information if necessary in the normal course of CMD business.</p>
                    <p><strong>Business Transfers</strong>: In the event of merger, dissolution, sale of assets or acquisition of CMD, user information would be one of the assets that is transferred. We reserve the right to disclose or transfer your personal data to the purchaser. You shall be notified via email and/or a notice on our websites in such events and choices available to you on your personal data.</p>
                    <p><strong>Data Storage</strong>: Your encrypted personal information is stored on servers provided by a third-party cloud-based service provider that protects and stores your data in a manner that is consistent with this Policy.
                    </p>
                    <p><strong>Protection of CMD and Others</strong>: We may share and disclose your personal information without notice or choice as required by applicable law, regulation, legal process or enforceable governmental authority. We also may disclose your information when we have reason to believe that someone is or may be causing harm to or interference with our rights, property or safety, or anyone who could be harmed by such activities.</p>
                    <p id="thirdparty"><strong>Related Entities and Authorized Third-Party Service Providers</strong>: We may share user information as well as information from tools such as cookies, log details, device ID and/or data location with our entities and/or third-party service providers as is reasonably to provide our services. Our service providers are obligated to only use the information to help us provide our services and not for any other purpose. This may include but not limited to performing support functions such as payment processing, business analytics, customer support, printing and mailing, data storage and destruction or marketing any of CMD products and services.</p>
                    <ul>
                        <li><p>Google Analytics</p>
                            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a></p>
                        </li>
                        <li><p>Google APIs</p>
                            <p>Should you choose to use the direct import and share functions of videos from and to YouTube within CMD, we will have the ability to i, 
                            authenticate and authorize access to YouTube APIs vi Google OAuth2.0 and ii, 
                            access your YouTube content including video and channel information via the YouTube APIs. 
                            The sole uses of this data are i, to populate your CMD account with your VODs so we can extract highlights for you and ii, 
                            to directly share your CMD highlights to your YouTube account. 
                            CMD's use of information received from Google API will adhere to the 
                            <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">Google API Services User Data Policy</a>, 
                            including the Limited Use requirements. CMD’s use of information received from YouTube APIs will adhere to the 
                            <a href="https://www.youtube.com/t/terms" target="_blank">YouTube Terms of Service</a>. CMD will not use these data for any other purpose. 
                            You may choose to revoke CMD’s access to your YouTube data via <a href="https://security.google.com/settings/security/permissions" target="_blank">Google security settings page</a> We will only store your YouTube data for no longer than 30 days. Hence every 30 days we will prompt you to re-authenticate CMD\'s access to your YouTube account. You can always opt to get your YouTube data deleted from our storage at any point by emailing us at <a href="mailto:privacy@eklip.se">privacy@eklip.se</a></p>
                        </li>
                    </ul>
                </li>
                <li id="rights">
                    <h2>Your rights over your personal information</h2>
                    <p>You can always opt not to disclose information or opt to limit to disclose certain information by editing your preferences in your Account Settings. However, please do note that you might not be able to access certain features of our websites.</p>
                    <p>If you have provided us with your email address, we may occasionally send you an email to tell you about our new product feature or solicit your feedback. You may also receive notification emails that inform you of actions performed on our website. You have the right to opt out of receiving these types of emails from us. However all system emails (e.g., policy update or password resetting) are mandatory until you terminate your registration with us.
                    On our websites, we use browser cookies (strings of information stored by websites on a visitor’s computer) to record and store NPII related to your navigation of our websites. This NPII includes your session information, access and usage preferences and patterns. Our lawful basis is to provide for personalization of your navigation on our websites and to improve our service delivery to you. If you do not wish to have cookies placed on your computer, you may set your browser(s) to refuse cookies before using or accessing our websites. You may also configure your browser to decline any cookie and to remove any existing cookie. However, do note that certain features of our websites may not function properly without the aid of cookies.
                    </p>
                </li>
                <li id="unsolicited">
                    <h2>Unsolicited Information</h2>
                    <p>You may supply feedback on existing products, suggestions on new features, and other Unsolicited Information to us. All Unsolicited Information shall be deemed non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.</p>
                </li>
                <li id="security">
                    <h2>Security and Retention</h2>
                    <p>Your CMD account information is protected by an encrypted password for your privacy and security. We restrict access to your personal information only to CMD employees, consultants and/or contractors who need the information to perform a specific job (e.g. a customer service representative). All of our employees are subject to strict contractual confidentiality obligations and are kept up to date on our privacy and security practices.</p>
                    <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. However, please note that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. Notwithstanding that, you shall also take precautionary and adopt industry best practices in preventing any such breach. You are responsible for maintaining the confidentiality of your password and account, and you are fully responsible for all activities that occur under your password or account. In the event of unauthorized use of your password or account or any other breach of security, you must notify CMD immediately and promptly change your password.</p>
                    <p>We shall retain your information for as long as needed to provide you our services. We shall retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We are committed to use your data in accordance with all applicable laws and regulations, including but not limited to laws governing privacy and data protection.</p>
                    <p>CMD retains ownership rights in your account, however, in relation to deletion, users may disconnect their account through CMD at any time. In the time that users disconnect their account, data and all information collected from users that may include the name, user name, password, email address, mobile phone number, address and/or location, zip code, account verification, payment and and the information when the Users choose the language and content preferences, account settings, video during the game play event or other information through connected social platforms have automatically been removed from CMD.</p>
                </li>
                <li id="links">
                    <h2 id="links">Links to Other Sites</h2>
                    <p>Our service may contain links directed to other third-party sites. Please note that we have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. Therefore, we strongly advise you to review the Privacy Policy of these websites.</p>
                </li>
                <li id="kids">
                    <h2>Children's Privacy</h2>
                    <p>Our services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take necessary actions.</p>
                </li>
                <li id="changes">
                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We advise you to review this page periodically for any changes. “Effective date” will be updated when changes are made and these changes are effective immediately, after they are posted on this page. If the changes are significant, we shall notify you of such changes by posting a more prominent notice on this page. </p>
                </li>
                <li id="condition">
                    <h2>Condition of Use</h2>
                    <p>If you decide to visit the CMD websites, your visit and any possible dispute over privacy is subject to this Privacy Policy and our Terms of Service, including limitations on damages, arbitration of disputes, and application of law.</p>
                </li>
                <li id="#contact">
                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy, do not hesitate to contact us at <a href="mailto:privacy@eklip.se">privacy@eklip.se</a>.</p>
                    <p>Effective: 6 October 2020</p>
                </li>
            </ul>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br><br></br>
        <br></br>
    </div>
    <div>
    </div>
    <a href="" id="navigateTop" onclick="topFunction()"> <i class="fa fa-chevron-circle-up"></i></a>
      </Container>
    </ThemeProvider>
    </div>
    </div>
  );
};

export default PolicyPage;