import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useGetTierByIdQuery } from "api/tierApi";
import TierForm from "components/organization/tierForm";

const TierPopup = (props) => {
  const { open, setOpen, onSubmit, id, actionLoading } = props;
  const tier = useGetTierByIdQuery(id);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} className="dialog-body">
        {(tier?.isFetching || actionLoading) && <LinearProgress />}
        <DialogTitle className="dialog-title">{id > 0 ? 'Update Tier' : 'Add Tier'}</DialogTitle>
        <DialogContent>
          <TierForm onSubmit={onSubmit} initialValues={
              tier?.data?.data.length === 0
                ? null
                : tier?.data?.data} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TierPopup;
