import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  TableRow,
  TableCell,
  LinearProgress,
  CardContent,
  FormControl,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import BaseTable from "components/TableCreator";
import { useNavigate, useLocation } from "react-router-dom";
import SecondaryButton from "components/Button";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./styles.scss";
import {
  useGetLiveStreamingCreatorQuery,
  useGetVodStreamingCreatorQuery,
  useGetSummaryCreatorQuery,
  useGetLiveStreamingCreatorSearchMutation,
  useGetVodStreamingCreatorSearchMutation
} from "api/creatorApi";
import { useGetTierByProgramQuery } from "api/tierApi";
import { useGetCreatorTypeByProgramQuery } from "api/creatorTypeApi";
import { CreatorSummaryBlock } from "components/creatorSummaryBlock";
import { MultipleSelectChip, SingleSelect, DateRangePickerEx } from "components/filterSelect";
import { useGetCountryAllQuery } from "api/countryApi";
import queryString from "query-string";
import Icons from "constants/icons";
import moment from "moment";
import { getPeriodsInMonths } from "helpers/utils";
import axios from "api/apiAxios";
import SearchComponent from "components/SearchComponent";
import { height } from "@mui/system";

const liveStreamingTableHeaders = [
  { label: "#", field: null },
  { label: "Page ID", field: "fb_page_id" },
  { label: "Page Name", field: "name" },
  { label: "Page Username", field: "first_name" },
  { label: "Tier", field: "tier_name" },
  { label: "Type", field: "creator_type_name" },
  { label: "Contract Type", field: "contract_name" },
  { label: "Region/Country", field: "country" },
];

const vodTableHeaders = [
  { label: "#", field: null },
  { label: "Page ID", field: "fb_page_id" },
  { label: "Page Name", field: "name" },
  { label: "Page Username", field: "first_name" },
  { label: "Tier", field: "tier_name" },
  { label: "Type", field: "creator_type_name" },
  { label: "Contract Type", field: "contract_name" },
  { label: "Region/Country", field: "country" },
];

const months = getPeriodsInMonths();
const currentMonth = moment().startOf("month").format("YYYY-MM-DD");

let params = {};

const CreatorList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);

  useEffect(() => {
    params = queryString.parse(location.search);
    if (!params.metric) {
      params.metric = "stream";
    }
    if (!params.status) {
      params.status = "1";
    }
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  }, [location.search]);

  const summary = useGetSummaryCreatorQuery();
  const countries = useGetCountryAllQuery();
  const tiers = useGetTierByProgramQuery({ program_id: 0 });
  const creatorLiveStream = useGetCreatorTypeByProgramQuery({ program_id: 2 });
  const creatorVoD = useGetCreatorTypeByProgramQuery({ program_id: 1 });
  const [creatorTypes, setCreatorTypeSearch] = useState(creatorLiveStream);
  const [month, setMonth] = useState(filter.period || currentMonth);
  const [status, setStatus] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSelectMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleSelectStatus = (event) => {
    setStatus(event.target.value);
  };

  const showDetail = (id) => {
    navigate(`/creator-management/creator-list/${id}`);
  };

  const renderLiveStreamingTableBody = (data = []) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={liveStreamingTableHeaders.length}></TableCell>
        </TableRow>
      );

    return data.map((item, index) => {
      return (
        <TableRow
          hover
          className="hover-row"
          tabIndex={-1}
          key={index}
          onClick={() => showDetail(item.id)}
        >
          <TableCell>{(1 - 1) * 5 + index + 1}</TableCell>
          <TableCell>{item.fb_page_id}</TableCell>
          <TableCell>{item.page_name}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.tier}</TableCell>
          <TableCell>{item.creator_type}</TableCell>
          <TableCell>{item.contract}</TableCell>
          <TableCell>{item.country}</TableCell>
        </TableRow>
      );
    });
  };

  const renderVoDTableBody = (data = []) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={vodTableHeaders.length}></TableCell>
        </TableRow>
      );
    return data.map((item, index) => {
      return (
        <TableRow
          hover
          className="hover-row"
          tabIndex={-1}
          key={index}
          onClick={() => showDetail(item.id)}
        >
          <TableCell>{(1 - 1) * 5 + index + 1}</TableCell>
          <TableCell>{item.fb_page_id}</TableCell>
          <TableCell>{item.page_name}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.tier}</TableCell>
          <TableCell>{item.creator_type}</TableCell>
          <TableCell>{item.contract}</TableCell>
          <TableCell>{item.country}</TableCell>
        </TableRow>
      );
    });
  };

  const [tab, setTab] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    const metric = newValue === "2" ? "vod" : "stream";
    if(newValue == '2')
    {
      setCreatorTypeSearch(creatorVoD);
    }
    else
    {
      setCreatorTypeSearch(creatorLiveStream);
    }
    setTab(newValue);
    navigate(`${location.pathname}?metric=${metric}`);
  };

  const tabStyle = {
    fontWeight: 600,
    textAlign: "center",
    flex: 1,
    maxWidth: "none",
    color: "#1267fc",
  };

  const [filterTierChange, setFilterTierChange] = React.useState(
    filter?.tier_id !== undefined
      ? filter?.tier_id.split(",").map(function (item) {
          return parseInt(item, 10);
        })
      : []
  );
  const [filterTypeChange, setFilterTypeChange] = React.useState(
    filter?.creator_type_id !== undefined ? filter?.creator_type_id : ""
  );
  const [country, setCountry] = useState(
    filter?.country_id !== undefined ? filter?.country_id : ""
  );
  const handleSelectCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleFilterTierChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTierChange(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterTypeChange = (event) => {
    setFilterTypeChange(event.target.value);
  };
  const handleFilterClear = () => {
    setFilterTierChange([]);
    setFilterTypeChange("");
    setMonth(currentMonth);
    setCountry("");
    setStartDate(null);
    setEndDate(null);
    params = { metric: params.metric, period: currentMonth };
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  };
  const handleFilterSubmit = () => {
    const filterUrl = {
      ...filter,
      creator_type_id: filterTypeChange,
      tier_id: filterTierChange.join(","),
      period: month,
      startDate: startDate,
      endDate: endDate,
      country_id: country,
      status: status
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    const url = queryString.stringify(filterUrl);
    navigate(`${location.pathname}?${url}`);
  };

  const redirectCreateCreator = () => {
    navigate("/creator-management/add-new-creator");
  };

  const importAction = () => {
    navigate("/creator-management/creator-import");
  };

  const [exportLoading, setExportLoading] = useState(false);
  const exportData = () => {
    setExportLoading(true);
    const filterUrl = {
      ...filter,
      creator_type_id: filterTypeChange,
      tier_id: filterTierChange.join(","),
      period: month,
      country_id: country,
      status: status
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    axios({
      url: "/creators-export", //your url
      method: "GET",
      params: filterUrl,
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `Creators_List_${month}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoading(false);
      });
  };

  const [isImportStatus, setIsImportStatus] = useState(false);

  const importData = () => {
    setIsImportStatus(true);
    const reader = new FileReader();
    var bodyFormData = new FormData();
    //bodyFormData.append('file', imageFile);
    axios({
      url: "/creators-import", //your url
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: ""
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `Creators_List_${month}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoading(false);
      });
  };

  const [searchText, setSearchText] = useState(null);
  const onSearchChange = (value) =>{
    setSearchText(value.target.value);
  }
  const [getSearchStreamingData, getSearchStreamingDataResp] = useGetLiveStreamingCreatorSearchMutation();
  const [getSearchVodData, getSearchVodDataResp] = useGetVodStreamingCreatorSearchMutation();
  const [creatorSearch, setCreatorSearch] = useState(null);

  const handSearchClick = async () =>{
    setExportLoading(true);
    const dataSearch = tab == '1' ? await getSearchStreamingData({
      ...params, searchText
    }).unwrap() : await getSearchVodData({
      ...params, searchText
    }).unwrap();
    setCreatorSearch(dataSearch);
    setExportLoading(false);
  }

  return (
    <>
      <Grid container className="creator-list">
        <Grid item md={12}>
          <Card variant="outlined" sx={{ mb: 2, mt: 2, borderRadius: "25px" }}>
            {summary.isFetching && <LinearProgress />}
            <CardContent>
              <div className="summary-header">
                <span className="summary-title">Summary</span>
                <ButtonGroup
                  variant=""
                  className="btn-stepper-scroll"
                  aria-label="text button group"
                >
                  <Button
                    size="small"
                    // onClick=""
                    className="btn-stepper"
                  >
                    <Icons.KEYBOARD_ARROW_LEFT
                      sx={{ fontSize: 36 }}
                      color="disabled"
                    />
                  </Button>
                  <Button
                    size="small"
                    // onClick=""
                    className="btn-stepper"
                  >
                    <Icons.KEYBOARD_ARROW_RIGHT
                      sx={{ fontSize: 36 }}
                      color="primary"
                    />
                  </Button>
                </ButtonGroup>
              </div>
              <CreatorSummaryBlock {...summary} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={12}>
          <Card variant="outlined" sx={{ mb: 2, mt: 2, borderRadius: "25px" }}>
            <CardContent>
              <div className="filter-header">
                <span className="filter-title">Filter Data</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Period
                  </Typography>
                  <FormControl className="form-select">
                    <InputLabel id="filterMonth">Select Month</InputLabel>
                    <Select
                      labelId="filterMonth"
                      label="Select Month"
                      value={month}
                      onChange={handleSelectMonth}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="h-select"
                    >
                      <MenuItem key={'period-clear'} value={''} />
                      {months.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/*
                <Grid item xs={6} md={4} sx={{ marginTop: "38px" }}>
                  <FormControl className="form-select">
                      <DateRangePickerEx
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </FormControl>
                      </Grid>*/}
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Region
                  </Typography>
                  <SingleSelect
                    loading={countries?.isFetching}
                    data={countries?.data?.data}
                    labelName="Select Country"
                    labelId="lb_filter_country"
                    id="filter_country"
                    inputId="filter_country_input"
                    defaultValue={country}
                    handleChange={handleSelectCountry}
                  />
                </Grid>
                <Grid item xs={6} md={4} sx={{ marginTop: "38px" }}>
                  <FormControl className="form-select">
                    <InputLabel id="filterStatus">Select Status</InputLabel>
                    <Select
                      labelId="filterStatus"
                      label="Select Status"
                      value={status}
                      onChange={handleSelectStatus}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="h-select"
                    >
                      <MenuItem key={'period-clear'} value={''} />
                        <MenuItem key="1" value="1">
                            Active
                        </MenuItem>
                        <MenuItem key='2' value="2">
                          Inactive
                        </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Contracts
                  </Typography>
                  <MultipleSelectChip
                    loading={tiers.isFetching}
                    data={tiers.data?.data}
                    labelName="Select Tier"
                    labelId="lb_filter_tier"
                    id="filter_tier"
                    inputId="filter_tier_input"
                    defaultValue={filterTierChange}
                    handleChange={handleFilterTierChange}
                  />
                </Grid>
                <Grid item xs={6} md={4} sx={{ marginTop: "38px" }}>
                  <FormControl className="form-select">
                    <SingleSelect
                      loading={creatorTypes?.isFetching}
                      data={creatorTypes?.data?.data}
                      labelName="Select Type"
                      labelId="lb_filter_type"
                      id="filter_type"
                      inputId="filter_type_input"
                      defaultValue={filterTypeChange}
                      handleChange={handleFilterTypeChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="btn-filter">
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-clear-filter"
                    onClick={handleFilterClear}
                  >
                    Clear
                  </Button>
                </FormControl>
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-submit-filter"
                    onClick={handleFilterSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Data table */}
        <Grid md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
              <div style={{ display: "flex" }} className="cp-table-content">
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      marginLeft: "19px",
                    }}
                  >
                    Creator List
                  </span>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    className="btn-add-new"
                    onClick={() => redirectCreateCreator()}
                  >
                    Create creator
                  </Button>
                  <SecondaryButton
                    style={{ float: "right" }}
                    variant="contained"
                    className="btn-export"
                    onClick={() => importAction()}
                  >
                    Import Data
                  </SecondaryButton>
                  <SecondaryButton
                    style={{ float: "right" }}
                    variant="contained"
                    className="btn-export"
                    onClick={() => exportData()}
                  >
                    Export Data
                  </SecondaryButton>
                  <SearchComponent handleClick={()=>handSearchClick()} onTextChange={(e)=>onSearchChange(e)} style={{ float: "right", height: "35px" }} />
                </div>
              </div>

              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChangeTab} centered>
                    <Tab sx={tabStyle} label="Live Streaming" value="1" />
                    <Tab sx={tabStyle} label="VoD" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <BaseTable
                    tableHeaders={liveStreamingTableHeaders}
                    renderBody={(data) => renderLiveStreamingTableBody(data)}
                    metric="stream"
                    filter={params}
                    serviceApi={useGetLiveStreamingCreatorQuery}
                    searchData={creatorSearch}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <BaseTable
                    tableHeaders={vodTableHeaders}
                    renderBody={(data) => renderVoDTableBody(data)}
                    filter={params}
                    serviceApi={useGetVodStreamingCreatorQuery}
                    searchData={creatorSearch}
                  />
                </TabPanel>
              </TabContext>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={exportLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreatorList;
