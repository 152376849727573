import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { selectAccessToken } from "../store/userSlice";
import { Outlet } from "react-router-dom";
import MiniDrawer from "./navSidebar";
import { Box } from "@mui/system";
import { CssBaseline, Container } from "@mui/material";
import Header from "./header";
import RouterBreadcrumbs from "./breadcrumb";
import { logout } from "store/userSlice";
import { api } from "api/api";
import "./dashboard.scss";
import jwtDecode from "jwt-decode";

const drawerWidth = 240;

function DashboardLayout({ children }) {
  const dispatch = useDispatch();
  const handleLogoutUser = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  };
  const [open, setOpen] = useState(true);
  const accessToken = useSelector(selectAccessToken);
  if (!accessToken || jwtDecode(accessToken).exp < Date.now() / 1000) {
    handleLogoutUser();
    return <Navigate to="/sign-in" replace />;
  }
  return (
    <Box className="main-container">
      <CssBaseline />
      <Header open={open} setOpen={setOpen} drawerWidth={drawerWidth}></Header>
      <MiniDrawer open={open} setOpen={setOpen}></MiniDrawer>
      <Container
        maxWidth={false}
        style={{ marginTop: "65px", minHeight: "100vh" }}
      >
        <RouterBreadcrumbs />
        <Outlet />
      </Container>
    </Box>
  );
}

export default DashboardLayout;
