import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Paper,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import FacebookLogin from "react-facebook-login";
  import Images from "constants/images";
  import { PageProcess } from "api/FacebookApi";
  import BaseTable from "components/TableCreator";
  import { TableRow, TableCell, Backdrop, CircularProgress } from "@mui/material";
  import { Alerts } from "components/alerts";
  import { useGetErrorTokenQuery } from "api/creatorPageApi";
  import { useGetInvitationLinkTokenQuery } from "api/fbTokenAPI";
  import "./styles.scss";
  import Icons from "constants/icons";
  
  const tableHeaders = [
    { label: "#", field: null },
    { label: "Page Name", field: "name" },
    { label: "Facebook page id", field: "fb_page_id" },
    { label: "Page username", field: "page_username" },
    { label: "Page email", field: "page_email" },
    { label: "Access token info", field: "access_token_info" },
  ];
  
  const TrackingTokens = () => {
    const [isloading, setLoading] = useState(false);
    const [alert, setAlert] = React.useState("");
    const [isrefetch, refetchData] = React.useState(false);
    const invitationLinkToken = useGetInvitationLinkTokenQuery();
  
    const responseFacebook = async (response) => {
      setAlert("");
      if(response && response.accessToken)
      {
        try
        {
          setLoading(true);
          await PageProcess.savePageToken(response.accessToken);
          setLoading(false);
          setAlert(<Alerts msg="Refresh tokens success" type="success" />);
          refetchData(true);
        }
        catch(ex)
        {
          setLoading(false);
          setAlert(<Alerts msg="Refresh tokens failed" type="error" />);
        }
      }
      else
      {
        setAlert(<Alerts msg="Cannot refresh access token" type="error" />);
      }
    };
  
    const renderTableBody = (data = []) => {
      if (data.length === 0)
        return (
          <TableRow>
            <TableCell colSpan={tableHeaders.length}></TableCell>
          </TableRow>
        );
  
      return data?.map((item, index) => {
        return (
          <TableRow
            hover
            className="hover-row"
            tabIndex={-1}
            key={index}
            //onClick={() => showDetail(item.creator_id)}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.fb_page_id}</TableCell>
            <TableCell>{item.page_username}</TableCell>
            <TableCell>{item.page_email}</TableCell>
            <TableCell style={{ maxWidth: "200px" }}>
              {item.access_token_info}
            </TableCell>
          </TableRow>
        );
      });
    };
    const copyInvitationLinkToClipboard = async () => {
      setAlert("");
      try {
        let currentUrl = window.location.href;
        currentUrl = currentUrl.replace("/creator-management/get-tokens", "");
        await navigator.clipboard.writeText(
          // `http://localhost:3000/invitation?token=` +
          currentUrl + `/invitation?token=` +
            invitationLinkToken?.data?.token
        );
        setAlert(<Alerts msg="Copied!" type="success" />);
      } catch (err) {
        setAlert(<Alerts msg="Failed to copy!" type="error" />);
      }
    };
    return (
      <>
        <Grid className="social-media-connect" md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card
              variant="outlined"
              className="connect"
              sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}
            >
              <CardContent>
                <Typography
                  color="text.secondary"
                  gutterBottom
                  className="connect-title"
                >
                  Refresh Tokens From Social Media Accounts
                </Typography>
                <Card sx={{ maxWidth: 345 }} className="connect-card">
                  <CardActionArea>
                    <Avatar
                      src={Images.ICON_FACEBOOK_GAME}
                      sx={{ width: 50, height: 50 }}
                      variant="square"
                      className="connect-card-image"
                    ></Avatar>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Facebook
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Facebook Page
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <FacebookLogin
                      appId={window.env.FACEBOOK_APP_ID}
                      cookie={false}
                      xfbml={true}
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="read_insights,pages_show_list,pages_read_engagement,pages_read_user_content,public_profile"
                      callback={responseFacebook}
                      onFailure={responseFacebook}
                      cssClass="facebook-button-login"
                      textButton="Refresh Tokens"
                    />
                  </CardActions>
                </Card>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "45vw",
                    margin: "20px auto;",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Invitation Link"
                    inputProps={{ "aria-label": "search google maps" }}
                    value={
                      // `http://localhost:3000/invitation?token=` +
                      `https://csp.maingames.com/invitation?token=` +
                      invitationLinkToken?.data?.token
                    }
                    readOnly
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <Tooltip title="Click to copy invitation link">
                    <IconButton
                      color="primary"
                      sx={{ p: "10px" }}
                      aria-label="directions"
                      onClick={() => copyInvitationLinkToClipboard()}
                    >
                      <Icons.COPY_ALL />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <BaseTable
          tableHeaders={tableHeaders}
          renderBody={(data) => renderTableBody(data)}
          serviceApi={useGetErrorTokenQuery}
          isRefetch={isrefetch}
        />
        {alert}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer - 1,
            position: "absolute",
            opacity: 0.5,
          }}
          open={isloading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  };
  
  export default TrackingTokens;
  