import React, { useState } from "react";
import TableOrganization from "components/TableOrganization";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import "./styles.scss";
import TypePopup from "components/organization/typePopup";
import {
  useGetCreatorTypeAllQuery,
  useAddNewCreatorTypeMutation,
  useDeleteCreatorTypeByIdsMutation,
} from "api/creatorTypeApi";
import ConfirmDialog from "components/confirmDialog";
import { Alerts } from "components/alerts";

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "program_name",
    label: "Program",
  },
  {
    id: "description",
    label: "Description",
  },
];
const renderTypeTableBody = (
  row,
  index,
  isSelected,
  handleClick,
  handleClickOpenDialog
) => {
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell
        padding="checkbox"
        onClick={(event) => handleClick(event, row.id)}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.code}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.program_name}</TableCell>
      <TableCell>{row.description}</TableCell>
      <TableCell align="left">
        <Link
          component="button"
          onClick={() => handleClickOpenDialog(row.id)}
          underline="none"
        >
          <Typography className="edit-link">Edit</Typography>
        </Link>
      </TableCell>
    </TableRow>
  );
};
const TypeData = () => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(0);
  const [ids, setIds] = useState([]);
  const [alert, setAlert] = React.useState("");
  const [deleteCreatorTypeByIds, deleteResp] =
    useDeleteCreatorTypeByIdsMutation();
  const [addNewCreatorType, addResp] = useAddNewCreatorTypeMutation();
  const creatorTypes = useGetCreatorTypeAllQuery(null, true);

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClickOpenDialogConfirm = (selected) => {
    setIds(selected);
    setOpenConfirm(true);
  };
  const actionDelete = async (selected) => {
    setOpenConfirm(false);
    setAlert();
    try {
      const response = await deleteCreatorTypeByIds(ids.join());
      if (response?.data.success) {
        setAlert(<Alerts msg={response?.data.message} type="success" />);
      }
    } catch (error) {
      setAlert(<Alerts msg={error} type="error" />);
    }
  };

  const submit = async (values) => {
    setAlert();
    const response = await addNewCreatorType(values).unwrap();
    if (response?.success) {
      setOpen(false);
      setAlert(<Alerts msg={response?.message} type="success" />);
    }
    try {
      // setOpen(false);
    } catch (error) {
    }
  };
  return (
    <>
      {(creatorTypes?.isFetching || deleteResp?.isLoading) && (
        <LinearProgress />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={creatorTypes?.isFetching || deleteResp?.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableOrganization
        headCells={headCells}
        data={creatorTypes?.data}
        renderBody={renderTypeTableBody}
        actionDelete={handleClickOpenDialogConfirm}
        actionClick={handleClickOpenDialog}
      />
      <TypePopup
        open={open}
        setOpen={setOpen}
        onSubmit={submit}
        id={id}
        actionLoading={addResp.isLoading}
      />
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        onSubmit={() => actionDelete(ids)}
        dialogTitle="Delete Creator Type"
        dialogContent="Do you want to delete?"
      />
      {alert}
    </>
  );
};
export default TypeData;
