import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../layouts/Layout";
import DashboardLayout from "../layouts/DashboardLayout";
import SignIn from "../pages/signIn/index";
import SignUp from "../pages/signUp/index";
import ForgotPassword from "../pages/forgotPassword";
import ActivationAccount from "../pages/activationAccount";
import routes from "./routes";
import Privacy from "pages/policy";
import InvitationLayout from "layouts/InvitationLayout";
import InvitationLink from "pages/invitationLink";

const RouteList = () => (
  <Router>
    <Routes>
      <Route
        path="/sign-in"
        element={
          <SignIn />
        }
        key="/sign-in"
      />
      <Route
        path="/sign-up"
        element={
          <Layout>
            {" "}
            <SignUp />{" "}
          </Layout>
        }
        key="/sign-up"
      />
      <Route
        path="/forgot-password"
        element={
          <Layout>
            {" "}
            <ForgotPassword />{" "}
          </Layout>
        }
        key="/forgot-password"
      />
      <Route
        path="/activation-account"
        element={
          <Layout>
            {" "}
            <ActivationAccount />{" "}
          </Layout>
        }
        key="/activation-account"
      />
      <Route
        path="/privacy"
        element={
          <div className="privacy-contents">
            <Privacy />
          </div>
        }
      />
      <Route
        path="/invitation"
        element={
          <div className="invitation-contents">
            <InvitationLayout>
              <InvitationLink />
            </InvitationLayout>
          </div>
        }
        key="/invitation"
      />
      <Route path="/" element={<DashboardLayout />}>
        {routes.map((route, index) => {
          return (
            <Route
              path={route.path}
              key={index}
              element={route.component}
            ></Route>
          );
        })}
      </Route>

      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </Router>
);

export default RouteList;
