import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import "./styles.scss";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FacebookLogin from "react-facebook-login";
import Images from "constants/images";
import {
  useGetCheckInvitationLinkTokenQuery,
  useUpdatePagesTokenMutation,
} from "api/fbTokenAPI";
import MuiAlert from "@mui/material/Alert";
import { Alerts } from "components/alerts";
import Paper from "@mui/material/Paper";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme();

const columns = [
  { id: "id", label: "Page ID", align: "left", minWidth: 170 },
  { id: "name", label: "Page Name", align: "center", minWidth: 125 },
  {
    id: "access_token",
    label: "Access Token",
    minWidth: 170,
    align: "left",
  },
];

const InvitationLink = () => {
  const [alert, setAlert] = React.useState("");
  const [pageData, setPageData] = React.useState(null);
  const [connectStatus, setConnectStatus] = React.useState(true);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const checkInvitationLinkToken = useGetCheckInvitationLinkTokenQuery(
    params.get("token")
  );
  const [updatePagesToken, updatePagesTokenResp] =
    useUpdatePagesTokenMutation();
  const responseFacebook = async (response) => {
    if (response.status === undefined) {
      setAlert();
      try {
        const apiRes = await updatePagesToken({
          token: params.get("token"),
          userID: response.userID,
          accessToken: response.accessToken,
        }).unwrap();
        setAlert(
          <Alerts
            msg={apiRes?.message}
            type={apiRes?.success ? "success" : "error"}
          />
        );
        setPageData(apiRes?.data);
      } catch (error) {
        setAlert(<Alerts msg={error?.data?.error.message} type="error" />);
        setPageData(null);
      }
    } else {
      setConnectStatus(false);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box className="invitation-container social-media-connect">
          {connectStatus ? (
            pageData ? (
              <>
                <Avatar
                  src={Images.INVITATION_SUCCESS}
                  sx={{ width: 260, height: 260 }}
                  variant="square"
                  className="connect-card-image"
                ></Avatar>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Successfully Connect
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", margin: "20px auto" }}
                >
                  Thank you for connecting your Facebook Page account to CSP
                  Dashboard. You can start use CSP Dashboard by using the link
                  that has been sent to your email
                </Typography>

                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table
                      sx={{ maxWidth: 450 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{
                                minWidth: column.minWidth,
                                fontWeight: 600,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pageData?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.id}
                            </TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              {row.access_token}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </>
            ) : (
              <>
                <Avatar
                  src={Images.ICON_FACEBOOK_GAME}
                  sx={{ width: 86, height: 86 }}
                  variant="square"
                  className="connect-card-image"
                ></Avatar>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Welcome to CSP3.0 Application.
                </Typography>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  You are invited to Connect to
                </Typography>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  CSP Global
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", margin: "30px auto" }}
                >
                  CSP Application is the best tool for creator managers to
                  monitor creator performance in real time and manage active new
                  creators. It will make your task as a creator manager much
                  simple and easy. Accept this invite and connect your facebook
                  account to register
                </Typography>
                {checkInvitationLinkToken?.data?.is_check ? (
                  <FacebookLogin
                    appId={window.env.FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="read_insights,pages_show_list,pages_read_engagement,pages_read_user_content,public_profile"
                    callback={responseFacebook}
                    cssClass="facebook-button-login"
                    textButton="Accept Invitation"
                  />
                ) : (
                  <Alert severity="error" sx={{ width: "100%" }}>
                    Incorrect token!
                  </Alert>
                )}
              </>
            )
          ) : (
            <>
              <>
                <Avatar
                  src={Images.INVITATION_ERROR}
                  sx={{ width: 260, height: 260 }}
                  variant="square"
                  className="connect-card-image"
                ></Avatar>
                <Typography
                  gutterBottom
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Facebook Page Not Found
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", margin: "20px auto" }}
                >
                  We can't find your Facebook page account on our list as an
                  eligible Facebook Page to have access to CSP Dashboard. Please
                  make sure the Facebook Page account you are use is correct
                </Typography>
              </>
            </>
          )}
        </Box>
        {alert}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer - 1,
            position: "absolute",
            opacity: 0.5,
          }}
          open={updatePagesTokenResp?.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
};

export default InvitationLink;
