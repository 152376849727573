import { api } from "./api";

export const countryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountryAll: builder.query({
      query: () => `countries`,
      subscribe: false,
      forceRefetch: true,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCountryAllQuery,
} = countryApi;
