import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { reduxForm } from "redux-form";
import ListItemText from "@mui/material/ListItemText";
import { Backdrop, CircularProgress, LinearProgress } from "@mui/material";
import { Field } from "redux-form";

let EditCreatorForm = (props) => {
  const {
    handleSubmit,
    submitting,
    activeStep,
    steps,
    contentStep,
    handleBack,
    handleNext,
    btnNextText,
    addResp,
  } = props;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ width: "100%", marginTop: "5vh" }}
    >
      <Field component="input" name="id" type="hidden" />
      <Paper elevation={3} className="paper-content">
        <ListItemText
          primary="Edit Creator"
          secondary="Please fill in this form below to edit Creator"
          className="title-top"
        />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="stepper-content"
        >
          {steps.map((label, index) => {
            const stepProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment>
          <Typography sx={{ mt: 5, mb: 1 }}>{contentStep}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant="contained"
              className="btn-back"
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              type="submit"
              onClick={handleNext}
              variant="contained"
              className="btn-next"
              disabled={submitting}
            >
              {btnNextText}
            </Button>
          </Box>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={addResp?.isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </React.Fragment>
      </Paper>
    </Box>
  );
};

EditCreatorForm = reduxForm({
  form: "kpiIndexForm",
  enableReinitialize: true,
})(EditCreatorForm);

export default EditCreatorForm;
