import React, { useState } from 'react';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import './styles.scss'

const theme = createTheme();

const errorStyle = {
  left: '10px',
  fontSize: '11px',
  color: 'red'
}

export default function ForgotPassword() {
  const submit = (event) => {

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          className='forgot-password-container'
        >
          <Typography className='typography' component="h1" variant="h5" >
            Forgot Password
          </Typography>
          <Box component="form" sx={{ mt: 1, width: '100%' }}>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <MailOutlinedIcon />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="email"
                fullWidth
                placeholder="Email"
                autoFocus
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit"
            // disabled={isLoading}
            >
              <span style={{
                fontSize: '20px',
                fontFamily: 'Poppins',
                textTransform: 'none',
                fontWeight: '700px'
              }}
              >Send Reset Link</span>
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}