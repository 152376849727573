import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = (props) => {
  const {handleClick, onTextChange, style} = props;

  const onKeyPress = (e) =>
  {
    if(e.key == 'Enter')
    {
        handleClick();
    }
  }

  return (
    <Paper
      //component="form"
      sx={{ p: '2px 4px', display: 'flex', marginRight: "5px", marginBottom: "2px", alignItems: 'center', width: 300 }}
      style={style}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search data' }}
        onChange={onTextChange}
        onKeyPress={onKeyPress}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search" onClick={handleClick}>
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
  );
}
export default SearchComponent;