import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { React, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import { useEffect } from "react";
import "./styles.scss";

const TextInputStandard = (props) => {
  const { label, type, input } = props;
  return (
    <TextField
      {...input}
      {...props}
      label={label}
      variant="standard"
      type={type}
    />
  );
};

const SelectStandard = (props) => {
  const { label, labelId, children, input, loading } = props;
  const [value, setValue] = useState(null);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, width: "100%" }} {...props}>
        <InputLabel id={labelId}>{label}</InputLabel>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        {value === null ? (
          <Select
            {...input}
            labelId={labelId}
            label={label}
            onChange={handleChange}
          >
            {children}
          </Select>
        ) : (
          <Select
            {...input}
            value={value}
            labelId={labelId}
            label={label}
            onChange={handleChange}
          >
            {children}
          </Select>
        )}
      </FormControl>
    </>
  );
};

const MultipleSelectStandard = (props) => {
  const { label, labelId, children, input, items, setCheckedValue, loading } = props;
  const [value, setValue] = useState([]);
  useEffect(() => {
    const lstIdDefault = input.value !== "" ? input.value : value;
    setValue(lstIdDefault);
    setCheckedValue(lstIdDefault);
  }, [input.value]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setCheckedValue(value);
  };
  return (
    <>
      <FormControl variant="standard" sx={{ m: 1, width: "100%" }} {...props}>
        <InputLabel id={labelId}>{label}</InputLabel>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        <Select
          {...input}
          className="multiple-select"
          labelId={labelId}
          multiple
          displayEmpty={true}
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map(
                (id) =>
                  items?.find((e) => e.id === id) && (
                    <Chip
                      key={id}
                      label={items?.find((e) => e.id === id).name} 
                      className="item-checked"
                    />
                  )
              )}
            </Box>
          )}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
};

const DatePickerStandard = (props) => {
  const { label, input, inputFormat } = props;
  const [value, setValue] = useState(null);
  const onChange = (date) => {
    if (date) {
      const dateStr = date.format(inputFormat ? inputFormat : "YYYY-MM-DD");
      input.onChange(dateStr);
      setValue(dateStr);
    }
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {input.value !== "" ? (
          <DatePicker
            {...input}
            label={label}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
            inputFormat={inputFormat ? inputFormat : "YYYY-MM-DD"}
          />
        ) : (
          <DatePicker
            {...input}
            label={label}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} />}
            inputFormat={inputFormat ? inputFormat : "YYYY-MM-DD"}
          />
        )}
      </LocalizationProvider>
    </>
  );
};

export {
  TextInputStandard,
  SelectStandard,
  MultipleSelectStandard,
  DatePickerStandard,
};
