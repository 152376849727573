
import { ReactComponent as ComingSoonSVG } from "../assets/images/coming-soon.svg";
import IconFacebookGame from "../assets/images/icon-facebook-game.png";
import InvitationSuccess from "../assets/images/invitation-success.png";
import InvitationError from "../assets/images/invitation-error.png";
const Images = {
    COMING_SOON: ComingSoonSVG,
    ICON_FACEBOOK_GAME: IconFacebookGame,
    INVITATION_SUCCESS: InvitationSuccess,
    INVITATION_ERROR: InvitationError,
}

export default Images;