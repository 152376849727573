import React, { useState } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import './styles.scss'
import { UploadFile } from "components/Input";
import importProcess from "./importProcess";
import Papa from "papaparse";
import ImportProcess from "./importProcess";
import FinishImport from "./finishImport";

const ImportCreators = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [basicInfo, setBasicInfo] = useState({
        file: null,
        path: '',
        data: null
    })
    
    const handleSwitchStep = (step) => {
        setActiveStep(activeStep + step);
    }

    const steps = [
        {
            name: 'Choose csv file',
            component: <UploadFile
                handleSwitchStep={handleSwitchStep}
                basicInfo={basicInfo}
                setBasicInfo={setBasicInfo}
                extensions='.csv'
            />
        },
        {
            name: 'Import process',
            component: <ImportProcess 
                            handleSwitchStep={handleSwitchStep}
                            basicInfo={basicInfo}
                            setBasicInfo={setBasicInfo}/>
        },
        {
            name: 'Finish',
            component: <FinishImport />
        }
    ]

    return (
        <Box sx={{ width: '100%', mt: 4 }}>
            <Stepper activeStep={activeStep}>
                {steps.map((step, index) => {
                    const stepProps = {};
                    return (
                        <Step key={step.name} {...stepProps} >
                            <StepLabel>
                                <Typography>{step.name}</Typography>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <>
                <Typography sx={{ mt: 2, mb: 1, color:"#1411D8" }}>Step {activeStep + 1}</Typography>
                <Box className='create-creator-layout'
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: "auto",
                            minWidth: 600,
                            height: 700,
                        },
                    }}
                >
                    <Paper elevation={3} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        {steps[activeStep].component}
                    </Paper>
                </Box>
            </>
        </Box>
    );
};

export default ImportCreators;