import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useGetCreatorTypeByIdQuery } from "api/creatorTypeApi";
import TypeForm from "components/organization/typeForm";

const TypePopup = (props) => {
  const { open, setOpen, onSubmit, id, actionLoading } = props;
  const creatorType = useGetCreatorTypeByIdQuery(id);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} className="dialog-body">
        {(creatorType.isFetching || actionLoading) && <LinearProgress />}
        <DialogTitle className="dialog-title">{id > 0 ? 'Update Creator Type' : 'Add Creator Type'}</DialogTitle>
        <DialogContent>
          <TypeForm
            onSubmit={onSubmit}
            initialValues={
              creatorType?.data?.data.length === 0
                ? null
                : creatorType?.data?.data
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TypePopup;
