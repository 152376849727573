import { createSlice } from "@reduxjs/toolkit";
import { userApi } from '../api/userApi';

const initialState = {
  currentUser: null,
  accessToken: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.accessToken = null;
      state.currentUser = null;
      // Clear all data in persist including other reducer data
      localStorage.removeItem('persist:root');
      localStorage.removeItem('token');
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.login.matchFulfilled, (state, action) => {
        state.accessToken = action.payload.token;
        localStorage.setItem('token', state.accessToken);
      })
      .addMatcher(userApi.endpoints.getUserInfo.matchFulfilled, (state, { payload }) => {
        state.currentUser = payload;
      })
  },
});

// Export actions
export const { logout, setCurrentUser } = userSlice.actions;

// Select state currentUser from slice
export const selectUser = (state) => state.user.currentUser;
export const selectAccessToken = (state) => state.user.accessToken;

// Export reducer
export default userSlice.reducer;