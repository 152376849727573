import { api } from "./api";

export const suspiciousEngagementApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["Restreaming"],
  endpoints: (builder) => ({
    getSuspiciousEngagementList: builder.query({
      query: (params) => ({
          url: `suspicious-engagement-list`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    getSuspiciousEngagementListSearch: builder.mutation({
      query: (params) => ({
          url: `suspicious-engagement-list`,
          method: 'GET',
          params: params
      }),
      // providesTags: ["Creators"],
    }),
  }),
  
  overrideExisting: true,
});

export const {
    useGetSuspiciousEngagementListQuery,
    useGetSuspiciousEngagementListSearchMutation
} = suspiciousEngagementApi;
