import React, { useState } from 'react';
import { InputAdornment, OutlinedInput, Select, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Icons from "constants/icons";
import './styles.scss'

const theme = createTheme();

const errorStyle = {
  left: '10px',
  fontSize: '11px',
  color: 'red'
}

export default function ActivationAccount() {

  const submit = (event) => {

  };

  const [age, setAge] = useState('')
  const handleChange = (event) => {
    setAge(event.target.value)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          className='activation-account-container'
        >
          <Typography className='typography' component="h1" variant="h5" >
            CSP Activation Account
          </Typography>
          <Box component="form" sx={{ mt: 1, width: '100%' }}>
            <Grid container columnSpacing={{ xs: 1 }} >
              <Grid item xs={6} sx={{ display: 'block', mb: '22px' }}>
                <Box sx={{ display: 'flex' }}>
                  <InputAdornment
                    className='input-icon'
                  >
                    <Icons.PERSON_OUTLINE_OUTLINED />
                  </InputAdornment>
                  <OutlinedInput
                    className="input-content-left"
                    name="firstName"
                    fullWidth
                    placeholder="First Name"
                    autoFocus
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  className="input-content"
                  name="lastName"
                  fullWidth
                  placeholder="Last Name"
                  autoFocus
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <Icons.PHONE_IPHONE_OUTLINED />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="phoneNumber"
                fullWidth
                placeholder="Phone Number"
                autoFocus
              />
            </Box>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <Icons.MAIL_OUTLINED />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="email"
                fullWidth
                placeholder="Email"
                autoFocus
              />
            </Box>
            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item  xs={6}>
                <Box sx={{ display: 'flex', mb: '22px' }}>
                  <InputAdornment
                    className="input-icon"
                  >
                    <Icons.APPS />
                  </InputAdornment>
                  <Select
                    value={age}
                    className="input-content-left"
                    onChange={handleChange}
                    displayEmpty
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <em>Chat apps</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item  xs={6}>
                <OutlinedInput
                  className="input-content"
                  name="id"
                  fullWidth
                  placeholder="ID"
                  autoFocus
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit"
            // disabled={isLoading}
            >
              <span>Activate account</span>
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}