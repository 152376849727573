import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { reduxForm } from "redux-form";
import { useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import { Step1, Step2, Step3, Step4 } from "components/addNewCreator";
import Icons from "constants/icons";
import {
  useAddNewCreatorMutation,
} from "api/creatorApi";
import "./styles.scss";
import { Backdrop, CircularProgress } from "@mui/material";
import { Alerts } from "components/alerts";
import { Field } from "redux-form";

const steps = [
  "Creator Profile",
  "Page Information",
  "Contract Information",
  "Billing Information",
];
let contentStep = "";
let initialValues = null;

let AddNewCreator = (props) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [addNewStatus, setAddNewStatus] = React.useState(0);
  const [btnNextText, setBtnNextText] = React.useState("Next");
  const [alert, setAlert] = React.useState("");
  const { handleSubmit, submitting } = props;
  const [addNewCreator, addResp] = useAddNewCreatorMutation();

  const showCreatorList = () => {
    navigate(`/creator-management/creator-list`);
  };

  const submit = async (form) => {
    setAlert();
    try {
      if (activeStep >= steps.length - 1) {
        const response = await addNewCreator(form).unwrap();
        if (response.id && response.id > 0) {
          setAddNewStatus(1);
          setAlert(<Alerts msg="Add new creator success" type="success" />);
        }
      } else {
        handleNext()
      }
    } catch (error) {
      setAddNewStatus(2);
      setAlert(<Alerts msg={error.data.error.message} type="error" />);
    }
  };

  React.useEffect(() => {
    if (activeStep === steps.length - 1) {
      setBtnNextText("Save");
    } else {
      setBtnNextText("Next");
    }
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };
  const handleReset = () => {
    setActiveStep(0);
    setAddNewStatus(0);
  };

  if (activeStep === 0) {
    contentStep = <Step1 />;
  } else if (activeStep === 1) {
    contentStep = <Step2 />;
  } else if (activeStep === 2) {
    contentStep = <Step3 />;
  } else if (activeStep === 3) {
    contentStep = <Step4 />;
  }

  return addNewStatus === 0 ? (
    <Box sx={{ width: "100%", marginTop: "5vh" }}>
      <form onSubmit={handleSubmit((form) => submit(form))}>
        <Field component="input" name="id" type="hidden" />
        <Paper elevation={3} className="paper-content">
          <ListItemText
            primary="Add New Creator"
            secondary="Please fill in this form below to add new Creator"
            className="title-top"
          />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="stepper-content"
          >
            {steps.map((label, index) => {
              const stepProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <React.Fragment>
            <Typography sx={{ mt: 5, mb: 1 }}>{contentStep}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                className="btn-back"
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                type="submit"
                variant="contained"
                className="btn-next"
                disabled={submitting}
              >
                {btnNextText}
              </Button>
              {alert}
            </Box>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={addResp?.isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </React.Fragment>
        </Paper>
      </form>
    </Box>
  ) : (
    <Box sx={{ width: "100%", marginTop: "5vh" }}>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          bgcolor: "background.default",
          display: "grid",
          padding: "68px",
        }}
      >
        <Typography component="span" className="notify-submit-form">
          {addNewStatus === 1 ? (
            <Icons.THUMB_UP_OUTLINED className="notify-ico" />
          ) : (
            <Icons.REPORT_GMAILERRORRED_OUTLINED className="notify-ico" />
          )}
        </Typography>
        {addNewStatus === 1 ? (
          <ListItemText
            primary="You’ve successfully submit your creator"
            secondary="Click the button below to see your new creator and an updated list"
            className="notify-content"
          />
        ) : (
          <ListItemText
            primary="Oooppss Something Went Wrong"
            secondary="Click the button below to refresh the page"
            className="notify-content"
          />
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        {addNewStatus === 1 ? (
          <Button
            onClick={() => showCreatorList()}
            variant="contained"
            className="btn-back-to-creator-list"
          >
            Back to Creator List
          </Button>
        ) : (
          <Button
            onClick={handleReset}
            variant="contained"
            className="btn-back-to-creator-list"
          >
            Try Again
          </Button>
        )}
      </Paper>
    </Box>
  );
};

AddNewCreator = reduxForm({
  form: "addNewCreator",
  destroyOnUnmount: true,
  // validate,
  initialValues,
})(AddNewCreator);
export default AddNewCreator;
