import { api } from "./api";

export const programApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProgramAll: builder.query({
      query: () => `programs`,
      subscribe: false,
      forceRefetch: true,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProgramAllQuery,
} = programApi;
