import { api } from "./api";

export const creatorPageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPageToken: builder.query({
      query: (params) => ({
        url: "getPageToken",
        method: 'GET',
        params: params,
        subscribe: false,
        forceRefetch: true,
        cacheTime: 0,
        staleTime: 1
      })
    }),
    getErrorToken: builder.query({
      query: (params) => ({
        url: "getPageToken",
        method: 'GET',
        params: {type:'error'},
        subscribe: false,
        forceRefetch: true,
        cacheTime: 0,
        staleTime: 1
      })
    }),
    getPageTokenSearch: builder.mutation({
      query: (params) => ({
        url: "getPageToken",
        method: 'GET',
        params: params,
        subscribe: false,
        forceRefetch: true,
        cacheTime: 0,
        staleTime: 1
      })
    })
  }),
  overrideExisting: true,
});

export const {
  useGetPageTokenQuery,
  useGetErrorTokenQuery,
  useGetPageTokenSearchMutation
} = creatorPageApi;
