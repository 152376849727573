import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import routes from "../routes/routes";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useGetLastInfoQuery } from "api/breadcrumbApi";

export default function RouterBreadcrumbs() {
  const location = useLocation();
  const pathNames = location.pathname.split("/").filter((x) => x);
  let { id } = useParams();
  const currentPathName =
    id === undefined
      ? location.pathname
      : location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  const lastInfo = useGetLastInfoQuery(
    { id: id, path_name: currentPathName },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "30px",
        marginBottom: "13px",
      }}
    >
      <Breadcrumbs
        separator={<FiberManualRecordIcon sx={{ fontSize: "10px" }} />}
        aria-label="breadcrumb"
      >
        {pathNames.map((value, index) => {
          const last = index === pathNames.length - 1;
          const to = `/${pathNames.slice(0, index + 1).join("/")}`;
          const currentRoute = routes.find((route) => route.path === to);

          return last
            ? currentRoute && (
                <Typography
                  color="text.primary"
                  key={to}
                  style={{
                    padding: "2px 16px",
                    background: "#1267FC",
                    borderRadius: "10px",
                    color: "#fff",
                    fontWeight: "500",
                    textDecoration: "none",
                  }}
                >
                  {currentRoute.name}
                </Typography>
              )
            : index > 0
            ? currentRoute && (
                <Typography
                  color="text.primary"
                  key={to}
                  style={{
                    padding: "2px 16px",
                    background: "#1267FC",
                    borderRadius: "10px",
                  }}
                >
                  <Link
                    to={to}
                    key={to}
                    style={{
                      color: "#fff",
                      fontWeight: "500",
                      textDecoration: "none",
                    }}
                  >
                    {currentRoute.name}
                  </Link>
                </Typography>
              )
            : currentRoute && (
                <Typography
                  color="text.primary"
                  key={to}
                  style={{
                    padding: "2px 16px",
                    background: "#1267FC",
                    borderRadius: "10px",
                    color: "#fff",
                    fontWeight: "500",
                    textDecoration: "none",
                  }}
                >
                  {currentRoute.name}
                </Typography>
              );
        })}
        {id !== undefined && (
          <Typography
            color="text.primary"
            key={null}
            style={{
              padding: "2px 16px",
              background: "#1267FC",
              borderRadius: "10px",
              color: "#fff",
              fontWeight: "500",
              textDecoration: "none",
            }}
          >
            {lastInfo?.data?.data}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
}
