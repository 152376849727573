import { api } from "./api";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
    getUserInfo: builder.query({
      query: () => "me",
      subscribe: false,
      forceRefetch: true,
    }),
    loginAndGetData: builder.mutation({
      async queryFn(credentials, { dispatch, getState }, option, fetchWithBQ) {
          // Login with credentials
          const loginRes = await dispatch(
            userApi.endpoints.login.initiate(credentials)
          );
          // Throw error if login was not success
          if (loginRes.error) return { error: loginRes.error.data.error };
          // Get user info and store it in state
          const userInfo = await dispatch(
            userApi.endpoints.getUserInfo.initiate()
          );
          // Return data or error when getting userInfo
          return userInfo.error
            ? { error: userInfo.error }
            : { data: userInfo.data };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useGetUserInfoQuery,
  useLoginAndGetDataMutation,
} = userApi;
