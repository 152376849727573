import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  TableRow,
  TableCell,
  CardContent,
  FormControl,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import BaseTable from "components/TableCreator";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.scss";
import {
  useGetSuspiciousEngagementListQuery,
  useGetSuspiciousEngagementListSearchMutation
} from "api/suspiciousEngagementApi";
import { SingleSelect, DateRangePickerEx } from "components/filterSelect";
import queryString from "query-string";
import SearchComponent from "components/SearchComponent";
import { useGetCreatorTypeByProgramQuery } from "api/creatorTypeApi";
import { Alerts } from "components/alerts";
import { useGetCountryAllQuery } from "api/countryApi";
import axios from "api/apiAxios";
import SecondaryButton from "components/Button";
import moment from "moment";
import { numberFormat } from "helpers/utils";

const tableHeaders = [
  { label: "#", field: null },
  { label: "Page Id", field: "id" },
  { label: "Page Name", field: "name" },
  { label: "Creator Type", field: "creator_type_id" },
  { label: "Contract Type", field: "contract_id" },
  { label: "Tier", field: "tier" },
  { label: "WHR", field: "watch_hour" },
  { label: "Views_60s", field: "views_60s" },
  { label: "Views", field: "views" },
  { label: "Reactions", field: "reactions" },
  { label: "Comments", field: "comments" },
  { label: "Shares", field: "shares" },
  { label: "Engagement", field: "engagement" },
  { label: "3s-Views / 1min-Views", field: "ratio_1" },
  { label: "WHR/views", field: "ratio_2" },
  { label: "Engagement/ / WHR", field: "ratio_3" },
  { label: "Engagement Flag", field: "suspicious_engagement_flag" },
];

let params = {};

const SuspiciousEngagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterTypeChange, setFilterTypeChange] = React.useState(
    filter?.creator_type !== undefined ? filter?.creator_type : ""
  );

  const handleFilterTypeChange = (event) => {
    setFilterTypeChange(event.target.value);
  };

  const handleFilterClear = () => {
    setFilterTypeChange("");
    setStartDate(null);
    setEndDate(null);
    setCountry("");
    params = { };
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  };

  const handleFilterSubmit = () => {
    const filterUrl = {
      ...filter,
      creator_type: filterTypeChange,
      from: startDate,
      to: endDate,
      country_id: country,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    const url = queryString.stringify(filterUrl);
    navigate(`${location.pathname}?${url}`);
  };

  const creatorTypes = useGetCreatorTypeByProgramQuery({ program_id: 0 });

  useEffect(() => {
    params = queryString.parse(location.search);
    
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  }, [location.search]);

  const [alert, setAlert] = React.useState("");

  const [country, setCountry] = useState(
    filter?.country_id !== undefined ? filter?.country_id : ""
  );
  const handleSelectCountry = (event) => {
    setCountry(event.target.value);
  };
  
  const renderSuspiciousEngagementTableBody = (data = []) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={tableHeaders.length}></TableCell>
        </TableRow>
      );

    return data.map((item, index) => {
      return (
        <TableRow
          hover
          className="hover-row"
          tabIndex={-1}
          key={index}
          //onClick={() => showDetail(item.id)}
        >
          <TableCell>{(1 - 1) * 5 + index + 1}</TableCell>
          <TableCell>{item.id}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.creator_type_id}</TableCell>
          <TableCell>{item.contract_id}</TableCell>
          <TableCell>{item.tier}</TableCell>
          <TableCell>{numberFormat(item.watch_hour, 2)}</TableCell>
          <TableCell>{numberFormat(item.views_60s, 2)}</TableCell>
          <TableCell>{numberFormat(item.views, 2)}</TableCell>
          <TableCell>{numberFormat(item.reactions, 2)}</TableCell>
          <TableCell>{numberFormat(item.comments, 2)}</TableCell>
          <TableCell>{numberFormat(item.shares, 2)}</TableCell>
          <TableCell>{numberFormat(item.engagement, 2)}</TableCell>
          <TableCell style={{ minWidth: "120px" }} className= {item.ratio_1_flag === 'Red' ? "hl-red" : (item.ratio_1_flag === 'Yellow' ? 'hl-yellow' : 'hl-green')}><span className="hl-text">{item.ratio_1}%</span></TableCell>
          <TableCell className= {item.ratio_2_flag === 'Red' ? "hl-red" : (item.ratio_2_flag === 'Yellow' ? 'hl-yellow' : 'hl-green')}><span className="hl-text">{item.ratio_2}</span></TableCell>
          <TableCell className={item.ratio_3_flag === 'Red' ? "hl-red" : (item.ratio_3_flag === 'Yellow' ? 'hl-yellow' : 'hl-green')}><span className="hl-text">{item.ratio_3}</span></TableCell>
          <TableCell className= {item.suspicious_engagement_flag === 'Red' ? "hl-red" : (item.suspicious_engagement_flag === 'Yellow' ? 'hl-yellow' : 'hl-green')}><span className="hl-text">{item.suspicious_engagement_flag}</span></TableCell>
        </TableRow>
      );
    });
  };

  const [searchText, setSearchText] = useState(null);
  const onSearchChange = (value) =>{
    setSearchText(value.target.value);
  }

  const [SearchData, setSearchData] = useState(null);
  const [getSearchData, getSearchDataRes] = useGetSuspiciousEngagementListSearchMutation();

  const handSearchClick = async () =>{
    setIsLoading(true);
    const dataSearch = await getSearchData({
      ...params, searchText
    }).unwrap();
    setSearchData(dataSearch);
    setIsLoading(false);
  }

  const countries = useGetCountryAllQuery();

  const exportData = () => {
    setIsLoading(true);
    const filterUrl = {
      ...filter,
      creator_type: filterTypeChange,
      from: startDate,
      to: endDate,
      country_id: country,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    axios({
      url: "/suspicious-engagement-list-export", //your url
      method: "GET",
      params: filterUrl,
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        let date = moment().format("YYYY-MM-DD");
        link.setAttribute("download", `suspicious_engagement_list_${date}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid container className="suspicious-engagement">
      <Grid item md={12}>
          <Card variant="outlined" sx={{ mb: 2, mt: 2, borderRadius: "25px" }}>
            <CardContent>
              <div className="filter-header">
                <span className="filter-title">Filter Data</span>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Period
                  </Typography>
                  <FormControl className="form-select">
                      <DateRangePickerEx
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Region
                  </Typography>
                  <SingleSelect
                    loading={countries?.isFetching}
                    data={countries?.data?.data}
                    labelName="Select Country"
                    labelId="lb_filter_country"
                    id="filter_country"
                    inputId="filter_country_input"
                    defaultValue={country}
                    handleChange={handleSelectCountry}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginLeft: "10px",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    By Creator Type
                  </Typography>
                  <FormControl className="form-select">
                    <SingleSelect
                      loading={creatorTypes?.isFetching}
                      data={creatorTypes?.data?.data}
                      labelName="Select Type"
                      labelId="lb_filter_type"
                      id="filter_type"
                      inputId="filter_type_input"
                      defaultValue={filterTypeChange}
                      handleChange={handleFilterTypeChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} className="btn-filter">
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-clear-filter"
                    onClick={handleFilterClear}
                  >
                    Clear
                  </Button>
                </FormControl>
                <FormControl sx={{ m: 1, float: "right" }}>
                  <Button
                    variant="contained"
                    className="btn-submit-filter"
                    onClick={handleFilterSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Data table */}
        {alert}
        <Grid md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
              <div style={{ display: "flex" }} className="cp-table-content">
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      marginLeft: "19px",
                    }}
                  >
                    Engagement List
                  </span>
                  <SecondaryButton
                    style={{ float: "right" }}
                    variant="contained"
                    className="btn-export"
                    onClick={() => exportData()}
                  >
                    Export Data
                  </SecondaryButton>
                  <SearchComponent handleClick={()=>handSearchClick()} onTextChange={(e)=>onSearchChange(e)} style={{ float: "right", height: "35px" }} />
                </div>
              </div>
                <BaseTable
                    tableHeaders={tableHeaders}
                    renderBody={(data) => renderSuspiciousEngagementTableBody(data)}
                    filter={params}
                    serviceApi={useGetSuspiciousEngagementListQuery}
                    searchData={SearchData}
                  />
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SuspiciousEngagement;
