import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Box } from "@mui/system";
import "./styles.scss";
import { styled } from '@mui/material/styles';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Papa from "papaparse";

const Input = styled('input')({
  display: 'none',
});

const TextInput = (props) => {
  const {
    startIcon,
    endIcon,
    endIconEvent,
    placeholder,
    type,
    input,
    meta: { touched, error },
  } = props;
  return (
    <Box>
      <div className="input-container">
        {startIcon && (
          <InputAdornment className="input-icon">{startIcon}</InputAdornment>
        )}
        <TextField
          sx={!startIcon ? { borderRadius: "20px !important" } : null}
          placeholder={placeholder}
          label={placeholder}
          type={type}
          className={startIcon ? "input-content" : "input-content-without-icon"}
          fullWidth
          {...props}
          {...input}
          error={touched && error}
          // helperText={touched && error}
        />
        {endIcon && (
          <InputAdornment
            position="end"
            className="end-icon"
            onClick={endIconEvent}
          >
            <IconButton
              aria-label="toggle visibility"
              onClick={endIconEvent}
              edge="end"
            >
              {endIcon}
            </IconButton>
          </InputAdornment>
        )}
      </div>
      {touched && error && <p className="error-input">{error}</p>}
    </Box>
  );
};

const SelectInput = (props) => {
  const {
    startIcon,
    labelId,
    id,
    placeholder,
    children,
    input,
    loading,
    meta: { touched, error },
  } = props;
  const [value, setValue] = useState(null);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Box>
      <div className="input-container">
        {startIcon ? (
          <InputAdornment className="input-icon">{startIcon}</InputAdornment>
        ) : null}
        <FormControl sx={{ width: "100%" }}  error={touched && error}>
          <InputLabel id={labelId} className="input-label">
            {placeholder}
          </InputLabel>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          {value === null ? (
            <Select
              {...input}
              labelId={labelId}
              label={placeholder}
              onChange={handleChange}
              className={
                startIcon ? "input-content-left" : "input-content-without-icon"
              }
            >
              {children}
            </Select>
          ) : (
            <Select
              labelId={labelId}
              {...input}
              id={id}
              className={
                startIcon ? "input-content-left" : "input-content-without-icon"
              }
              displayEmpty
              fullWidth
              value={value}
              label={placeholder}
              onChange={handleChange}
            >
              {children}
            </Select>
          )}
        </FormControl>
      </div>
      {touched && error && <p className="error-input">{error}</p>}
    </Box>
  );
};

const DatePickerInput = (props) => {
  const {
    startIcon,
    placeholder,
    input,
    inputFormat,
    meta: { touched, error },
  } = props;
  const [value, setValue] = useState(null);
  const onChange = (date) => {
    if (date) {
      const dateStr = date.format(inputFormat ? inputFormat : "YYYY-MM-DD");
      input.onChange(dateStr);
      setValue(dateStr);
    }
  };
  return (
    <Box>
      <div className="input-container">
        {startIcon ? (
          <InputAdornment className="input-icon">{startIcon}</InputAdornment>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {input.value !== "" ? (
            <DesktopDatePicker
              {...input}
              label={placeholder}
              inputFormat="YYYY-MM-DD"
              onChange={onChange}
              {...props}
              renderInput={(params) => {
                console.log("params", params)
                console.log("error", error)
                return <TextField {...params} {...input} className="input-datepicker" error={params.error || (touched && error)} />
              }}
            />
          ) : (
            <DesktopDatePicker
              {...input}
              label={placeholder}
              inputFormat="YYYY-MM-DD"
              value={value}
              onChange={onChange}
              {...props}
              renderInput={(params) => {
                console.log("params", params)
                console.log("error", error)
                return <TextField {...params} {...input} className="input-datepicker" error={params.error || (touched && error)} />
              }}
            />
          )}
        </LocalizationProvider>
      </div>
      {touched && error && <p className="error-input">{error}</p>}
    </Box>
  );
};

const UploadFile = (props) => {
  const {
      handleSwitchStep,
      basicInfo,
      setBasicInfo,
      extensions
  } = props;

  const addFile = (e) => {
      if (e.target.files[0]) {

        const reader = new FileReader();

        const data = [];
         
        // Event listener on reader when the file
        // loads, we parse it and set the data.
        reader.onloadend = ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            setBasicInfo({...basicInfo, ...{
              file: e.target.files[0],
              path: URL.createObjectURL(e.target.files[0]),
              data:csv?.data
            }});
        };
        reader.readAsText(e.target.files[0]);
      }
  }

  return (
      <>
          <Box
              className="upload-file"
              sx={{ mt: 4, width: "80%", height: "90%" }}
          >
              <Typography sx={{ mt: 2, mb: 5}}>
                  Create creators from a file upload
              </Typography>
              <label htmlFor="icon-button-file">
                  <Input accept={extensions} id="icon-button-file" type="file" onChange={addFile} />
                  <IconButton color="primary" disableRipple className='icon-upload' aria-label="upload sound" component="span" sx={{
                      "&:hover": {
                          backgroundColor: "#FFF"
                      },
                  }}>
                      <UploadIcon sx={{
                          fontSize: 80,
                          border: 3,
                          borderRadius: '50%',
                          borderColor: 'grey.500',
                          color: 'grey.500',
                      }} />
                  </IconButton>
              </label>
              {
                    basicInfo.file?.name
              }
              <Box className="navigate-box">
                  <Button
                      color="inherit"
                      disabled
                      onClick={()=>handleSwitchStep(-1)}
                      sx={{ mr: 1 }}
                  >
                      Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button disabled={!basicInfo.path} onClick={()=>handleSwitchStep(1)}>
                      Next
                  </Button>
              </Box>
          </Box>
      </>
  )
};

export { TextInput, SelectInput, DatePickerInput, UploadFile };
