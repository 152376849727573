import { api } from "./api";

export const contractApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["Contracts"],
  endpoints: (builder) => ({
    getContractAll: builder.query({
      query: () => ({
          url: `contracts`,
          method: 'GET',
          subscribe: false
      }),
      providesTags: ["Contracts"],
    }),
    getContractById: builder.query({
      query: (id) => ({
          url: `contract/${id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
      providesTags: ["Contracts"],
    }),
    getContractByProgram: builder.query({
      query: (params) => ({
          url: `contracts/${params.program_id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
    }),
    addNewContract: builder.mutation({
      query: (credentials) => ({
        url: "contract",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Contracts"],
    }),
    deleteContractByIds: builder.mutation({
      query: (ids) => ({
        url: `contract/${ids}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contracts"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetContractAllQuery,
  useGetContractByIdQuery,
  useGetContractByProgramQuery,
  useAddNewContractMutation,
  useDeleteContractByIdsMutation,
} = contractApi;
