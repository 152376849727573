import React, { useState } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Paper,
  styled,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import "./styles.scss";
import { PropTypes } from "prop-types";
import { getSelectInMonths } from "helpers/utils";
import { deepOrange } from "@mui/material/colors";
import { numberFormat } from "helpers/utils";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DashboardCreatorRankBlock = (props) => {
  const { data, error, rankMonth, setRankMonth, rankType, setRankType, creatorType, setCreatorType } =
    props;
  const rank = data?.data;

  const months = getSelectInMonths();

  const handleChangeTab = (event, newValue) => {
    setRankType(newValue);
  };

  const handleSelectMonth = (event) => {
    setRankMonth(event.target.value);
  };

  const handleSelectCreatorType = (event) => {
    setCreatorType(event.target.value);
  };
  return (
    <>
      <div style={{ display: "flex", marginLeft:"auto" }}>
        <Select
          className="select-option"
          value={rankMonth}
          onChange={handleSelectMonth}
          displayEmpty
        >
          {months.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <Select
          className="select-option"
          value={creatorType}
          onChange={handleSelectCreatorType}
          displayEmpty
        >
          <MenuItem key="creatorType2" value="L">
              Live
          </MenuItem>
          <MenuItem key="creatorType1" value="V">
              VoD
          </MenuItem>
        </Select>
      </div>
      <Paper className="tab-content">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={rankType}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            variant="fullWidth"
            centered
            className="tab-title"
          >
            <Tab label="Top 5" {...a11yProps(0)} />
            <Tab label="Bottom 5" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={rankType} index={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="th-title">
                    Creators
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Tier
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Type
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Contract
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Watch Hour
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rank?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={row.name}
                          className="creator-name"
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell align="center">{row.tier}</TableCell>
                    <TableCell align="center">{row.creator_type}</TableCell>
                    <TableCell align="center">{row.contract}</TableCell>
                    <TableCell align="center">{numberFormat(row.watch_hours, 2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={rankType} index={1}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className="th-title">
                    Creators
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Tier
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Type
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Contract
                  </TableCell>
                  <TableCell align="center" className="th-title">
                    Watch Hour
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rank?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={row.name}
                          className="creator-name"
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell align="center">{row.tier}</TableCell>
                    <TableCell align="center">{row.creator_type}</TableCell>
                    <TableCell align="center">{row.contract}</TableCell>
                    <TableCell align="center">{numberFormat(row.watch_hours)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Paper>
    </>
  );
};

export { DashboardCreatorRankBlock };
