import { api } from "./api";

export const breadcrumbApi = api.injectEndpoints({
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getLastInfo: builder.query({
      query: (params) => {
        const { id } = params;
        let filter = { ...params };
        delete filter.id;
        return {
          url: `breadcrumb-last-info/${id}`,
          method: 'GET',
          params: filter,
          subscribe: false
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetLastInfoQuery,
} = breadcrumbApi;
