import { api } from "./api";

export const fbTokenAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getInvitationLinkToken: builder.query({
      query: () => ({
          url: `invitation-link-token`,
          method: 'GET'
      }),
    }),
    getCheckInvitationLinkToken: builder.query({
      query: (token) => ({
          url: `check-invitation-link-token?token=${token}`,
          method: 'GET'
      }),
    }),
    updatePagesToken: builder.mutation({
      query: (credentials) => ({
        url: "update-pages-token",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetInvitationLinkTokenQuery,
  useGetCheckInvitationLinkTokenQuery,  
  useUpdatePagesTokenMutation,
} = fbTokenAPI;
