import moment from "moment";

export const getPeriodsInMonths = () => {
  let months = [];
  for (let i = 11; i >= 0; --i) {
    let recent = moment().subtract(i, "months");
    let date = recent.startOf("month").format("YYYY-MM-DD");
    let month = recent.format("MMMM");
    months.push({ value: date, name: month });
  }

  return months;
};

export const getSelectInMonths = () => {
  let months = [];
  for (let i = 1; i >= 0; --i) {
    let recent = moment().subtract(i, "months");
    let date = recent.startOf("month").format("YYYY-MM-DD");
    months.push({ value: date, name: i === 0 ? 'This Month' : 'Last Month' });
  }

  return months;
};

export const numberFormat = (number, maximumFractionDigits = 0) => {
  return Number(number).toLocaleString(undefined, { maximumFractionDigits: maximumFractionDigits });
};
