import React from "react";
import Box from "@mui/material/Box";
import { Paper, Typography } from "@mui/material";

const InvitationLayout = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "#1267FC",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        component="span"
        sx={{
          margin: "0 auto",
          display: "flex",
          fontSize: "6vw",
          color: "#fff",
          fontWeight: "200",
        }}
      >
        Welcome
      </Typography>
      <Paper
        sx={{
          width: "45vw",
          height: "86vh",
          borderRadius: "50px",
          mr: "5%",
          boxShadow: "0px 10px 25px 2px rgba(0, 0, 0, 0.4)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default InvitationLayout;
