import React from "react";
import { Field, reduxForm } from "redux-form";
import { Box, Button, MenuItem } from "@mui/material";
import { TextInputStandard, SelectStandard } from "components/InputStandard";
import { useGetProgramAllQuery } from "api/programApi";

let TypeForm = (props) => {
  const { handleSubmit, submitting } = props;
  const programs = useGetProgramAllQuery();
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field component="input" name="id" type="hidden" />
        <Field
          component={SelectStandard}
          id="program_id"
          name="program_id"
          label="Program"
          loading={programs.isFetching}
          required
        >
          {programs.data?.data.map((creator) => (
            <MenuItem value={creator.id}>{creator.name}</MenuItem>
          ))}
        </Field>
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="name"
          name="name"
          label="Name"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="description"
          name="description"
          label="Description"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, float: "right" },
        }}
      >
        <Button
          variant="contained"
          className="btn-submit-dialog"
          type="submit"
          disabled={submitting}
          xs={{ float: "right" }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

TypeForm = reduxForm({
  form: "typeForm",
  enableReinitialize: true,
})(TypeForm);

export default TypeForm;
