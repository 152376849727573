import { api } from "./api";

export const creatorApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["Creators"],
  endpoints: (builder) => ({
    getSummaryCreator: builder.query({
      query: () => `creator/summary`,
      subscribe: false,
      forceRefetch: true,
    }),
    getLiveStreamingCreator: builder.query({
      query: (params) => ({
          url: `creators`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    getVodStreamingCreator: builder.query({
      query: (params) => ({
          url: `creators`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    getProfileCreator: builder.query({
      query: (params) => ({
          url: `creator/profile/${params.id}`,
          method: 'GET'
      }),
    }),
    getCreatorDetail: builder.query({
      query: (id) => ({
          url: `creator-detail/${id}`,
          method: 'GET'
      }),
    }),
    getVideosCreator: builder.query({
      query: (params) => {
        const { id } = params;
        let filter = { ...params };
        delete filter.id;
        return {
          url: `creator/videos/${id}`,
          method: 'GET',
          params: filter,
          subscribe: false,
          forceRefetch: true,
        };
      },
    }),
    getCreatorGames: builder.query({
      query: () => ({
          url: `creator-games`,
          method: 'GET'
      }),
    }),
    addNewCreator: builder.mutation({
      query: (credentials) => ({
        url: "creator",
        method: "POST",
        body: credentials,
      }),
      providesTags: ["Creators"],
    }),
    updateCreator: builder.mutation({
      query: (credentials) => ({
        url: `creator/${credentials.id}`,
        method: "PUT",
        body: credentials,
      }),
      providesTags: ["Creators"],
    }),
    getCreatorByTenant: builder.query({
      query: () => `creator-by-tenant`,
      subscribe: false,
      forceRefetch: true,
    }),
    getLiveStreamingCreatorSearch: builder.mutation({
      query: (params) => ({
          url: `creators`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    getVodStreamingCreatorSearch: builder.mutation({
      query: (params) => ({
          url: `creators`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    ImportNewCreator: builder.mutation({
      query: (credentials) => ({
        url: "creators-import",
        method: "POST",
        body: credentials,
      })
    }),
  }),
  
  overrideExisting: true,
});

export const {
  useGetSummaryCreatorQuery,
  useGetLiveStreamingCreatorQuery,
  useGetVodStreamingCreatorQuery,
  useGetProfileCreatorQuery,
  useGetCreatorDetailQuery,
  useGetVideosCreatorQuery,
  useGetCreatorGamesQuery,
  useAddNewCreatorMutation,
  useUpdateCreatorMutation,
  useGetCreatorByTenantQuery,
  useGetLiveStreamingCreatorSearchMutation,
  useGetVodStreamingCreatorSearchMutation,
  useImportNewCreatorMutation
} = creatorApi;
