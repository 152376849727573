import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Avatar,
  Typography,
  Box,
  CardHeader,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  TableRow,
  TableCell,
  CardContent,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import "./styles.scss";
import { Alerts } from "components/alerts";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { CreatorProfileDetail } from "components/creatorProfileDetail";
import BaseTable from "components/TableCreator";
import queryString from "query-string";
import {
  useGetVideosCreatorQuery,
  useGetProfileCreatorQuery
} from "api/creatorApi";
import SecondaryButton from "components/Button";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";


const tabs = [
  { label: "Profile", value: 0 },
  { label: "Video", value: 1 },
];

const videosTableHeaders = [
  { label: "#", field: null },
  { label: "Video ID", field: "fb_video_id" },
  { label: "Date", field: "video_created_date" },
  { label: "Time", field: "video_created_time" },
  { label: "Video Length", field: "length" },
  { label: "Game Name", field: "game_name" },
  { label: "Status", field: "status" },
  { label: "Total Reach", field: "reactions" },
  { label: "Total View", field: "view_time" },
  { label: "Total Comment", field: "comments" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ").length > 1 ? name.split(" ")[1][0] : name.split(" ")[0][1]
    }`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreatorDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);

  const [tab, setTab] = useState();
  const handleChangeTab = (event, newTab) => {
    const selectedTab = tabs.find((item) => item.value === newTab);
    if (selectedTab.label.toLowerCase() === "profile") {
      resetState();
      navigate(`${location.pathname}`);
    } else {
      filter = { period, metric, duration, tab: selectedTab.label.toLowerCase() };
      const url = queryString.stringify(filter);
      navigate(`${location.pathname}?${url}`);
    }
    setTab(newTab);
  };
  
  const [period, setDate] = useState(filter.period || moment().format("YYYY-MM-DD"));
  const [metric, setMetric] = useState(filter?.metric || "stream");
  const [duration, setDuration] = useState(filter?.duration || -1);
  const [listDuration, setListDuration] = useState(null);

  const handleChangeDate = (event) => {
    setDate(event)
    if(moment(event).isValid()) {
      filter.period = moment(event).format("YYYY-MM-DD")
      const url = queryString.stringify(filter);
      navigate(`${location.pathname}?${url}`);
    }
  }

  const handleSelectMetric = (event) => {
    setMetric(event.target.value);
    filter.metric = event.target.value;
    setDuration(-1);
    filter.duration = -1;
    const url = queryString.stringify(filter);
    navigate(`${location.pathname}?${url}`);
  };

  const handleSelectDuration = (event) => {
    setDuration(event.target.value);
    filter.duration = event.target.value;
    const url = queryString.stringify(filter);
    navigate(`${location.pathname}?${url}`);
  };

  const resetState = () => {
    // setMonth(currentMonth);
    setDate(moment().format("YYYY-MM-DD"))
    setMetric("stream");
    setListDuration([{'id':-1, 'name':'All'}, {'id':101, 'name': 'Above 59 minutes'}]);
    setDuration(-1);
  };

  useEffect(() => {
    let tabValue = 0;
    if (filter.tab) {
      const selectedTab = tabs.find(
        (item) => item.label.toLowerCase() === filter.tab
      );
      tabValue = selectedTab.value;
    }
    setTab(tabValue);
  }, []);

  useEffect(() => {
    if (filter.tab && filter.tab !== "profile") {
      if (!filter.metric) {
        filter.metric = "stream";
      }
      if (!filter.period) {
        filter.period = moment().format('YYYY-MM-DD');
      }
      const url = queryString.stringify(filter);
      navigate(`${location.pathname}?${url}`);
    }
  }, [location.search]);

  useEffect(() => {
    if(metric === "stream")
    {
      setListDuration([{'id':-1, 'name':'All'}, {'id':101, 'name': 'Above 59 minutes'}]);
    }
    else
    {
      setListDuration([{'id':-1, 'name':'All'}, {'id':201, 'name': 'Above 2 minutes 50'}]);
    }
    setDuration(-1);
  }, [metric]);

  const { id } = useParams();
  const profile = useGetProfileCreatorQuery({ id });

  const redirectEditCreator = () => {
    navigate("/creator-management/edit-creator/" + id);
  };

  const renderVideosTableBody = (data = []) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={videosTableHeaders.length}></TableCell>
        </TableRow>
      );

    return data.map((item, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          <TableCell>{(1 - 1) * 5 + index + 1}</TableCell>
          <TableCell>{item.fb_video_id}</TableCell>
          <TableCell>{item.video_created_date}</TableCell>
          <TableCell>{item.video_created_time}</TableCell>
          <TableCell>{item.length}</TableCell>
          <TableCell>{item.game_name}</TableCell>
          <TableCell>{item.status}</TableCell>
          <TableCell>{item.reactions}</TableCell>
          <TableCell>{item.view_time}</TableCell>
          <TableCell>{item.comments}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Grid container className="creator-list">
      <Grid md={3}>
        <Card
          variant="outlined"
          className="summary"
          sx={{ borderRadius: "25px" }}
        >
          <CardHeader
            avatar={
              profile?.data?.data === null ||
              profile?.data?.data === undefined ? (
                "UN"
              ) : (
                <Avatar {...stringAvatar(profile?.data?.data.page_name)} />
              )
            }
            title={profile?.data?.data.page_name}
            subheader={"Page ID: " + profile?.data?.data.page_id}
          />
        </Card>
      </Grid>

      <Grid md={11} spacing={5}>
        <Box sx={{ width: "100%" }}>
          <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "25px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                {tabs.map((tab, index) => (
                  <Tab
                    className="tab-title"
                    value={tab.value}
                    label={tab.label}
                    {...a11yProps(index)}
                  ></Tab>
                ))}
              </Tabs>
              <div className="btn-edit-creator">
                <SecondaryButton onClick={() => redirectEditCreator()}>
                  Edit Data
                </SecondaryButton>
              </div>
            </Box>
            <TabPanel value={tab} index={0}>
              <CreatorProfileDetail {...profile} />
            </TabPanel>
            <TabPanel value={tab} index={1} className="tab-videos">
              <Grid item style={{ display: "flex" }}>
                <Card
                  variant="outlined"
                  sx={{ borderRadius: "25px" }}
                  style={{ display: "inline-block", margin: "auto" }}
                >
                  <CardContent style={{ padding: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["month", "year"]}
                        label="Year and Month"
                        value={period}
                        onChange={(event) => (handleChangeDate(event))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={null}
                            sx={[
                              {
                                // borderRadius: "10px",
                                fontSize: "12px",
                                marginRight: 5,
                              },
                              { fieldset: { borderRadius: "10px" } },
                            ]}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <Select
                      value={metric}
                      onChange={handleSelectMetric}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        borderRadius: "10px",
                        fontSize: "12px",
                        marginRight: 5,
                      }}
                    >
                      <MenuItem key="0" value="stream">
                        Stream
                      </MenuItem>
                      <MenuItem key="1" value="vod">
                        VoD
                      </MenuItem>
                    </Select>

                    <Select
                      value={duration}
                      onChange={handleSelectDuration}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ borderRadius: "10px", fontSize: "12px" }}
                    >
                      {listDuration?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </CardContent>
                </Card>
              </Grid>
              <BaseTable
                tableHeaders={videosTableHeaders}
                renderBody={(data) => renderVideosTableBody(data)}
                serviceApi={useGetVideosCreatorQuery}
              />
            </TabPanel>
          </Card>
        </Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer - 1,
            position: "absolute",
            opacity: 0.5,
          }}
          open={profile.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {profile.error && (
          <Alerts msg={profile.error.data.error.message} type="error" />
        )}
      </Grid>
    </Grid>
  );
};

export default CreatorDetail;
