import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import "./styles.scss";
import { CircularProgress, ListItemText, Checkbox } from "@mui/material";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { CalendarToday } from "@material-ui/icons";
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { Popover } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultipleSelectChip = (props) => {
  const {
    loading,
    data,
    labelName,
    labelId,
    id,
    inputId,
    defaultValue,
    handleChange,
    cusClass,
  } = props;
  const theme = useTheme();

  return (
    <div>
      <FormControl className={`form-select ${cusClass}`}>
        <InputLabel id={labelId}>{labelName}</InputLabel>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        <Select
          labelId={labelId}
          id={id}
          multiple
          value={defaultValue}
          onChange={handleChange}
          input={<OutlinedInput id={inputId} label={labelName} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map(
                (id) =>
                  data?.find((e) => e.id === id) && (
                    <Chip
                      key={id}
                      label={data?.find((e) => e.id === id).name}
                      className="item-checked"
                    />
                  )
              )}
            </Box>
          )}
          MenuProps={MenuProps}
          className="h-select"
        >
          {data?.map((value) => (
            <MenuItem
              key={value.id}
              value={value.id}
              style={getStyles(value.id, defaultValue, theme)}
            >
              <Checkbox checked={defaultValue.indexOf(value.id) > -1} />
              <ListItemText primary={value.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const SingleSelect = (props) => {
  const {
    loading,
    data,
    labelName,
    labelId,
    id,
    defaultValue,
    handleChange,
    cusClass,
  } = props;

  return (
    <div>
      <FormControl className={`form-select ${cusClass}`}>
        <InputLabel id={labelId}>{labelName}</InputLabel>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        <Select
          labelId={labelId}
          id={id}
          value={defaultValue}
          label={labelName}
          onChange={handleChange}
          className="h-select"
          autoWidth={true}
        >
          <MenuItem key={'period-clear'} value={''} />
          {data?.map((value, index) => (
            <MenuItem value={value.id} key={index}>
              {value.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const DateRangePickerEx = props => {
  const {setStartDate, setEndDate, style, cusClass} = props;
  const [dateRange, setDateRange] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => setAnchorEl(null);
  const handleOnChange = (range) => {
    setStartDate(range.startDate?.toLocaleDateString('en-CA').slice(0,10));
    setEndDate(range.endDate?.toLocaleDateString('en-CA').slice(0,10));
    setDateRange(range.startDate?.toLocaleDateString() + ' - ' + range.endDate?.toLocaleDateString());
  };

  return (
    <div>
      <FormControl className={`form-select ${cusClass}`}>
      <Paper
      //component="form"
      sx={{ p: '6px 1px', mt: '10px', ml:'8px', display: 'flex' }}
      style={style}
      //className={`form-select`}
      autoWidth={true}
      className="h-select"
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Date Range"
        inputProps={{ 'aria-label': 'Date Range' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        value={dateRange}
      />
      <IconButton sx={{ p: '10px' }} aria-label="search" onClick={(e) => setAnchorEl(e.currentTarget)} >
        <CalendarToday />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
    <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <DateRangePicker
          open={true}
          toggle={handleClose}
          onChange={handleOnChange}
        />
      </Popover>
    </FormControl>
    </div>
  );
}

export { MultipleSelectChip, SingleSelect, DateRangePickerEx };
