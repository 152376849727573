import { Box, Typography } from "@mui/material"
import Button from '@mui/material/Button'

let FinishImport = () => {
    return (
        <>
            <Box
                className="finish-upload"
                sx={{ mt: 4, width: "80%", height: "90%" }}
            >
                <Typography sx={{fontSize: "25px", mt:4, textAlign: "center", color:"#4caf50"}}>
                    Successfully import creators!
                </Typography>
                <Button sx={{mt: 4}}>
                    <a href="/">
                        Go to dashboard
                    </a>
                </Button>
            </Box>
        </>
    )
}

export default FinishImport