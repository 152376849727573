import React from 'react';
import Home from 'pages/home';
import LiveStreaming from 'pages/creatorPerformance/liveStreaming';
import VoD from 'pages/creatorPerformance/vod';
import AddNewCreator from 'pages/addNewCreator';
import LiveStreamingDetail from 'pages/creatorPerformance/liveStreaming/detail';
import VoDDetail from 'pages/creatorPerformance/vod/detail';
import CreatorList from 'pages/creatorManagement/creatorList';
import CreatorDetail from 'pages/creatorManagement/creatorDetail/index';
import Organization from  'pages/organization/index';
import EditCreator from './../pages/editCreator/index';
import GetTokens from 'pages/getTokens';
import TrackingTokens from 'pages/trackingTokens';
import RestreamingCheckList from 'pages/restreamingcheck';
import SuspiciousEngagement from 'pages/suspiciousEngagement';
import ImportCreators from 'pages/creatorManagement/creatorImport';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    auth: true,
    component: <Home />,
  },
  {
    path: '/creator-performance',
    name: 'Creator performance',
    component: <div>Creator performance </div>,
  },
  {
    path: '/creator-performance/live-streaming',
    name: 'Live Streaming',
    component: <LiveStreaming />,
  },
  {
    path: '/creator-performance/live-streaming/:id',
    name: 'Live Streaming Detail',
    component: <LiveStreamingDetail />,
  },
  {
    path: '/creator-performance/vod',
    name: 'VoD',
    component: <VoD />,
  },
  {
    path: '/creator-performance/vod/:id',
    name: 'VoD Detail',
    component: <VoDDetail />,
  },
  {
    path: '/creator-management',
    name: 'Creator Management',
    component: <div>Creator management </div>,
  },
  {
    path: '/creator-management/creator-list',
    name: 'Creator List',
    component: < CreatorList/>,
  },
  {
    path: '/creator-management/creator-import',
    name: 'Creator Import',
    component: < ImportCreators/>,
  },
  {
    path: '/creator-management/creator-list/:id',
    name: 'Creator Detail',
    auth: true,
    component: <CreatorDetail />,
  },
  {
    path: '/creator-management/add-new-creator',
    name: 'Create creator',
    component: <AddNewCreator />
  },
  {
    path: '/creator-management/edit-creator/:id',
    name: 'Create creator',
    component: <EditCreator />
  },
  {
    path: '/creator-management/get-tokens',
    name: 'Get Tokens',
    component: <GetTokens />
  },
  {
    path: '/creator-management/tracking-tokens',
    name: 'Tracking FB tokes',
    component: <TrackingTokens />
  },
  {
    path: '/organization/kpi-index',
    name: 'KPI Index',
    component: <Organization/>
  },
  {
    path: '/quality-control',
    name: 'Quality Control',
    component: <div>Quality Control</div>,
  },
  {
    path: '/quality-control/restreaming-check',
    name: 'Restreaming Check',
    component: <RestreamingCheckList/>,
  },
  {
    path: '/quality-control/suspicious-engagement',
    name: 'Engagement',
    component: <SuspiciousEngagement/>,
  },
  {
    path: '/organization/type',
    name: 'Type',
    component: <Organization/>
  },
  {
    path: '/organization/tier',
    name: 'Tier',
    component: <Organization/>
  },
  {
    path: '/organization/contract',
    name: 'Contract',
    component: <Organization/>
  }
];

export default routes;
