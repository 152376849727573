import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useGetCreatorKpiByIdQuery } from "api/creatorKpiApi";
import KPIIndexForm from "components/organization/kpiIndexForm";

const KPIIndexPopup = (props) => {
  const { open, setOpen, onSubmit, id, actionLoading } = props;
  const creatorKpi = useGetCreatorKpiByIdQuery(id);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} className="dialog-body">
        {(creatorKpi?.isFetching || actionLoading) && <LinearProgress />}
        <DialogTitle className="dialog-title">{id > 0 ? 'Update KPI Index' : 'Add KPI Index'}</DialogTitle>
        <DialogContent>
          <KPIIndexForm
            onSubmit={onSubmit}
            initialValues={
              creatorKpi?.data?.data.length === 0
                ? null
                : creatorKpi?.data?.data
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KPIIndexPopup;
