import React from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Paper,
  styled,
  LinearProgress,
} from "@mui/material";
import "./styles.scss";
import { PropTypes } from "prop-types";
import linearProgressClasses from "@mui/material/LinearProgress";
import { getSelectInMonths } from "helpers/utils";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DashboardTargetBlock = (props) => {
  const { data, error, targetMonth, setTargetMonth, metric, setMetric } = props;
  const target = data?.data;
  const months = getSelectInMonths();

  const handleChangeTab = (event, newValue) => {
    setMetric(newValue);
  };

  const handleSelectMonth = (event) => {
    setTargetMonth(event.target.value);
  };
  return (
    <>
      <Select
        className="select-time"
        value={targetMonth}
        onChange={handleSelectMonth}
        displayEmpty
      >
        {months.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <Paper className="tab-content">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={metric}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            variant="fullWidth"
            centered
            className="tab-title"
          >
            <Tab label="Live Streaming" value="stream" {...a11yProps('stream')} />
            <Tab label="VoD" value="vod" {...a11yProps('vod')} />
          </Tabs>
        </Box>
        <TabPanel value={metric} index={'stream'}>
          <Box sx={{ position: "relative" }}>
            <Typography style={{ display: "inline-block", color: "#000000" }}>
              Broadcast Hour
            </Typography>
            <Typography
              style={{
                display: "inline-block",
                float: "right",
                color: "#000000",
              }}
            >
              {target?.stream_broadcast_hours_progress}%
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={target?.stream_broadcast_hours_progress}
            />
          </Box>
          <Box sx={{ position: "relative", mt: "15px" }}>
            <Typography style={{ display: "inline-block", color: "#000000" }}>
              Watched Hour
            </Typography>
            <Typography
              style={{
                display: "inline-block",
                float: "right",
                color: "#000000",
              }}
            >
              {target?.stream_watch_hours_progress}%
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={target?.stream_watch_hours_progress}
            />
          </Box>
        </TabPanel>
        <TabPanel value={metric} index={'vod'}>
          <Box sx={{ position: "relative" }}>
            <Typography style={{ display: "inline-block", color: "#000000" }}>
              Broadcast Hour
            </Typography>
            <Typography
              style={{
                display: "inline-block",
                float: "right",
                color: "#000000",
              }}
            >
              {target?.video_broadcast_hours_progress}%
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={target?.video_broadcast_hours_progress}
            />
          </Box>
          <Box sx={{ position: "relative", mt: "15px" }}>
            <Typography style={{ display: "inline-block", color: "#000000" }}>
              Watched Hour
            </Typography>
            <Typography
              style={{
                display: "inline-block",
                float: "right",
                color: "#000000",
              }}
            >
              {target?.video_watch_hours_progress}%
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={target?.video_watch_hours_progress}
            />
          </Box>
        </TabPanel>
      </Paper>
    </>
  );
};

export { DashboardTargetBlock };
