import { api } from "./api";

export const restreamingCheckApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["Restreaming"],
  endpoints: (builder) => ({
    getRestremingCheckList: builder.query({
      query: (params) => ({
          url: `restreming-check-list`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
      // providesTags: ["Creators"],
    }),
    getIndicatorList: builder.query({
      query: (params) => ({
          url: `indicator-list`,
          method: 'GET',
          params: params,
          subscribe: false,
          forceRefetch: true,
      }),
    }),
    updateRestreamStatus: builder.mutation({
      query: (credentials) => ({
        url: "restreming-check-update-status",
        method: "POST",
        body: credentials,
      }),
      providesTags: ["restreaming-check"],
    }),
    getRestremingCheckListSearch: builder.mutation({
      query: (params) => ({
          url: `restreming-check-list`,
          method: 'GET',
          params: params
      }),
      // providesTags: ["Creators"],
    }),
  }),
  
  overrideExisting: true,
});

export const {
  useGetRestremingCheckListQuery,
  useGetIndicatorListQuery,
  useUpdateRestreamStatusMutation,
  useGetRestremingCheckListSearchMutation
} = restreamingCheckApi;
