import React, { useState, useEffect } from "react";
import TableOrganization from "components/TableOrganization";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Link,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import "./styles.scss";
import KPIIndexPopup from "components/organization/kpiIndexPopup";
import {
  useGetCreatorKpisQuery,
  useAddNewCreatorKpiMutation,
  useDeleteCreatorKpiByIdsMutation,
  useGetCreatorKpisSearchMutation
} from "api/creatorKpiApi";
import { Alerts } from "components/alerts";
import ConfirmDialog from "components/confirmDialog";

const headCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "creator_name",
    label: "Creator Name",
  },
  {
    id: "streams",
    label: "Streams",
  },
  {
    id: "stream_watch_hours",
    label: "Stream Watch Hours",
  },
  {
    id: "stream_broadcast_hours",
    label: "Stream Broadcast Hours",
  },
  {
    id: "videos",
    label: "Videos",
  },
  {
    id: "video_watch_hours",
    label: "Video Watch Hours",
  },
  {
    id: "video_broadcast_hours",
    label: "Video Broadcast Hours",
  },
  {
    id: "start_date",
    label: "Start Date",
  },
  {
    id: "end_date",
    label: "End Date",
  },
];
const renderKPIIndexTableBody = (
  row,
  index,
  isSelected,
  handleClick,
  handleClickOpenDialog
) => {
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      <TableCell
        padding="checkbox"
        onClick={(event) => handleClick(event, row.id)}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.creator_name}</TableCell>
      <TableCell>{row.streams}</TableCell>
      <TableCell>{row.stream_watch_hours}</TableCell>
      <TableCell>{row.stream_broadcast_hours}</TableCell>
      <TableCell>{row.videos}</TableCell>
      <TableCell>{row.video_watch_hours}</TableCell>
      <TableCell>{row.video_broadcast_hours}</TableCell>
      <TableCell>{row.start_date}</TableCell>
      <TableCell>{row.end_date}</TableCell>
      <TableCell align="left">
        <Link
          component="button"
          onClick={() => handleClickOpenDialog(row.id)}
          underline="none"
        >
          <Typography className="edit-link">Edit</Typography>
        </Link>
      </TableCell>
    </TableRow>
  );
};
const KPIIndexData = () => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(0);
  const [ids, setIds] = useState([]);
  const [alert, setAlert] = React.useState("");
  const creatorKpis = useGetCreatorKpisQuery(null, true);
  const [deleteCreatorKpiByIds, deleteResp] =
    useDeleteCreatorKpiByIdsMutation();
  const [addNewCreatorKpi, addResp] = useAddNewCreatorKpiMutation();

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClickOpenDialogConfirm = (selected) => {
    setIds(selected);
    setOpenConfirm(true);
  };
  const actionDelete = async (ids) => {
    setOpenConfirm(false);
    setAlert();
    try {
      const response = await deleteCreatorKpiByIds(ids.join());
      if (response?.data.success) {
        setAlert(<Alerts msg={response?.data.message} type="success" />);
      }
    } catch (error) {
      setAlert(<Alerts msg={error} type="error" />);
    }
  };

  const submit = async (values) => {
    try {
      const response = await addNewCreatorKpi(values).unwrap();
      if (response?.success) {
        setOpen(false);
        setAlert(<Alerts msg={response?.message} type="success" />);
      }
    } catch (error) {}
  };

  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const onSearchChange = (value) =>{
    setSearchText(value.target.value);
  }
  const [getCreatorKpisSearch, getCreatorKpisSearchResp] = useGetCreatorKpisSearchMutation();
  const [creatorSearch, setCreatorSearch] = useState(null);

  const handSearchClick = async () =>{
    setLoading(true);
    const dataSearch = await getCreatorKpisSearch({
      searchText
    }).unwrap();
    setCreatorSearch(dataSearch);
    setLoading(false);
  }

  useEffect(() => {
    setCreatorSearch(creatorKpis?.data);
  }, [creatorKpis]);

  return (
    <>
      {(creatorKpis?.isFetching || deleteResp?.isLoading) && <LinearProgress />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={creatorKpis?.isFetching || deleteResp?.isLoading || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableOrganization
        headCells={headCells}
        data={creatorSearch}
        renderBody={renderKPIIndexTableBody}
        actionDelete={handleClickOpenDialogConfirm}
        actionClick={handleClickOpenDialog}
        visibleSearch={true}
        handSearchClick={()=>handSearchClick()}
        onSearchChange={(e)=>onSearchChange(e)}
      />
      <KPIIndexPopup
        open={open}
        setOpen={setOpen}
        onSubmit={submit}
        id={id}
        actionLoading={addResp.isLoading}
      />
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        onSubmit={() => actionDelete(ids)}
        dialogTitle="Delete KPI Index"
        dialogContent="Do you want to delete?"
      />
      {alert}
    </>
  );
};
export default KPIIndexData;
