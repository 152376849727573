import React, { useEffect } from "react";
import { MenuItem } from "@mui/material";
import {
  TextInput,
  SelectInput,
  DatePickerInput,
} from "components/Input/index";
import { Field } from "redux-form";
import Grid from "@mui/material/Grid";
import Validation from "helpers/validation";
import Icons from "constants/icons";
import { useGetProgramAllQuery } from "api/programApi";
import { useGetCountryAllQuery } from "api/countryApi";
import { useGetTierByProgramQuery } from "api/tierApi";
import { useGetCreatorTypeByProgramQuery } from "api/creatorTypeApi";
import { useGetContractByProgramQuery } from "api/contractApi";

const Step1 = (props) => {
  const countries = useGetCountryAllQuery();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.PERSON_OUTLINE_SHARP />}
          placeholder="Creator Name"
          name="first_name"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.BADGE_OUTLINED />}
          placeholder="ID Card Number"
          name="id_card_num"
          // validate={[Validation.required]}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ROOM_OUTLINED />}
          placeholder="Address"
          name="address"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.SMARTPHONE />}
          placeholder="Phone Number"
          name="phone"
          // validate={[Validation.required]}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          component={DatePickerInput}
          startIcon={<Icons.CAKE_OUTLINED />}
          placeholder="Birth Date"
          name="birthday"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.MAIL_OUTLINED />}
          placeholder="Creator Email"
          name="email"
          // validate={[Validation.required]}
        />
      </Grid>

      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.LANGUAGE />}
          placeholder="Country"
          name="country_id"
          labelId="country_id_label"
          loading={countries.isFetching}
        >
          {countries.data?.data.map((country) => (
            <MenuItem value={country.id}>{country.name}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.TRANSGENDER />}
          placeholder="Gender"
          name="gender"
          // validate={[Validation.required]}
        >
          <MenuItem value="1">Female</MenuItem>
          <MenuItem value="2">Male</MenuItem>
          <MenuItem value="0">Other</MenuItem>
        </Field>
      </Grid>
    </Grid>
  );
};

const Step2 = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.FIND_IN_PAGE_OUTLINED />}
          placeholder="Page ID"
          name="page_id"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.CONTACT_PAGE_OUTLINED />}
          placeholder="Page Name"
          name="page_name"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.REQUEST_PAGE_OUTLINED />}
          placeholder="Page Username"
          name="page_username"
          // validate={[Validation.required]}
        />
      </Grid>
    </Grid>
  );
};
const Step3 = () => {
  const [program, setProgram] = React.useState(0);
  const getProgram = (prog) => {
    setProgram(prog);
  };
  const programs = useGetProgramAllQuery();
  const tiers = useGetTierByProgramQuery({ program_id: program });
  const creatorTypes = useGetCreatorTypeByProgramQuery({ program_id: program });
  const contracts = useGetContractByProgramQuery({ program_id: program });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.CROP_169_OUTLINED />}
          placeholder="Program"
          name="program_id"
          loading={programs.isFetching}
          getData={getProgram}
          // validate={[Validation.required]}
        >
          {programs.data?.data.map((program) => (
            <MenuItem value={program.id}>{program.name}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.STAR_BORDER_OUTLINED />}
          placeholder="Tier"
          name="tier_id"
          id="tier_id"
          loading={tiers.isFetching}
          // validate={[Validation.required]}
        >
          {tiers.data?.data.map((tier) => (
            <MenuItem value={tier.id}>{tier.name}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.LOCAL_ATM_OUTLINED />}
          placeholder="Share"
          name="share"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.ARTICLE_OUTLINED />}
          placeholder="Contract"
          name="contract_id"
          loading={contracts.isFetching}
          // validate={[Validation.required]}
        >
          {contracts.data?.data.map((contract) => (
            <MenuItem value={contract.id}>{contract.name}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          component={DatePickerInput}
          startIcon={<Icons.DATE_RANGE_OUTLINED />}
          placeholder="Join Date"
          name="contract_start_date"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.GROUP_OUTLINED />}
          placeholder="Type"
          name="creator_type_id"
          loading={creatorTypes.isFetching}
          // validate={[Validation.required]}
        >
          {creatorTypes.data?.data.map((creatorType) => (
            <MenuItem value={creatorType.id}>{creatorType.name}</MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <Field
          component={SelectInput}
          startIcon={<Icons.CIRCLE_OUTLINED />}
          placeholder="Status"
          name="is_active"
          // validate={[Validation.required]}
        >
          <MenuItem value="1">Active</MenuItem>
          <MenuItem value="0">InActive</MenuItem>
        </Field>
      </Grid>
    </Grid>
  );
};

const Step4 = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ACCOUNT_BALANCE_OUTLINED />}
          placeholder="Bank Name"
          name="bank_name"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ACCOUNT_BALANCE_OUTLINED />}
          placeholder="Bank Account Name"
          name="bank_account_name"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ACCOUNT_BALANCE_OUTLINED />}
          placeholder="Bank Account ID"
          name="bank_account_number"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ACCOUNT_BALANCE_OUTLINED />}
          placeholder="Bank Swift Code"
          name="bank_swift_code"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.ACCOUNT_BALANCE_OUTLINED />}
          placeholder="Bank Address"
          name="bank_address"
          // validate={[Validation.required]}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          component={TextInput}
          startIcon={<Icons.FACEBOOK_OUTLINED />}
          placeholder="FB Remittance ID"
          name="fb_remittance_id"
          // validate={[Validation.required]}
        />
      </Grid>
    </Grid>
  );
};
export { Step1, Step2, Step3, Step4 };
