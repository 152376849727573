import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import ContentPasteSearchRoundedIcon from "@mui/icons-material/ContentPasteSearchRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import SettingsInputComponentRoundedIcon from "@mui/icons-material/SettingsInputComponentRounded";
import ForwardOutlinedIcon from "@mui/icons-material/ForwardOutlined";
import { Link, useLocation } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { logout } from "store/userSlice";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { api } from "api/api";
import FactCheckIcon from '@mui/icons-material/FactCheck';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ open, setOpen }) {
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const dispatch = useDispatch();
  let [toggles, setToggles] = useState([]);
  const showChildMenu = (pathname) => {
    if (toggles.find((item) => item === pathname)) {
      toggles = toggles.filter((item) => item !== pathname);
      setToggles(toggles);
    } else {
      setToggles([toggles, pathname]);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  };

  const location = useLocation();
  const parentElement = document.querySelector("#menu-list");
  if (parentElement) {
    parentElement.querySelectorAll("a").forEach((element) => {
      if (element.href.replace(/^.*\/\/[^\/]+/, "") === location.pathname) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }

  return (
    <Box>
      <Drawer
        PaperProps={{
          sx: {
            background: "#1267FC",
            color: "#fff",
            fontWeight: 600,
            fontSize: "15px",
          },
        }}
        variant="permanent"
        className="nav-sidebar"
        open={open}
      >
        <List className="logo">
          <ListItem button>
            <ListItemIcon>
              <MenuIcon sx={{ color: "#fff" }} onClick={handleDrawerToggle} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>
                  CSP Global
                </Typography>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List id="menu-list">
          <ListItem
            button
            autoFocus
            component={Link}
            to="/"
            className="list-item"
            onClick={() => showChildMenu("/")}
          >
            <ListItemIcon>
              <DashboardCustomizeOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText className="list-item--text" primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => showChildMenu("/creator-performance/live-streaming")}
          >
            <ListItemIcon>
              <SpeedRoundedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Creator Performance" />
            {toggles.find(
              (item) => item === "/creator-performance/live-streaming"
            ) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={toggles.find(
              (item) => item === "/creator-performance/live-streaming"
            )}
            timeout="auto"
            unmountOnExit
            className="child-menu"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/creator-performance/live-streaming"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Live Streaming" />
              </ListItem>
              <ListItem button component={Link} to="/creator-performance/vod">
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="VoD" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => showChildMenu("/creator-management/creator-list")}
          >
            <ListItemIcon>
              <LayersRoundedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Creator Management" />
            {toggles.find(
              (item) => item === "/creator-management/creator-list"
            ) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={toggles.find(
              (item) => item === "/creator-management/creator-list"
            )}
            timeout="auto"
            unmountOnExit
            className="child-menu"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/creator-management/creator-list"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Creator List" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/creator-management/add-new-creator"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Create creator" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/creator-management/get-tokens"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Get Tokens" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/creator-management/tracking-tokens"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Tracking FB tokes" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => showChildMenu("/quality-control/restreaming-check")}
          >
            <ListItemIcon>
              <ContentPasteSearchRoundedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Quality Control" />
            {toggles.find(
              (item) => item === "/quality-control/restreaming-check"
            ) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={toggles.find(
              (item) => item === "/quality-control/restreaming-check"
            )}
            timeout="auto"
            unmountOnExit
            className="child-menu"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/quality-control/restreaming-check"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Restreaming Check" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/quality-control/suspicious-engagement"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Engagement" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => showChildMenu("/organization/kpi-index")}
          >
            <ListItemIcon>
              <AccountTreeRoundedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Organization" />
            {toggles.find((item) => item === "/organization/kpi-index") ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={toggles.find((item) => item === "/organization/kpi-index")}
            timeout="auto"
            unmountOnExit
            className="child-menu"
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={Link}
                to="/organization/kpi-index"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="KPI Index" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/organization/type"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Type" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/organization/tier"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Tier" />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/organization/contract"
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Contract" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button>
            <ListItemIcon>
              <SettingsInputComponentRoundedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Setting" />
          </ListItem>
        </List>
        <List sx={{ marginTop: "auto" }}>
          <ListItem button onClick={() => handleLogout()}>
            <ListItemIcon>
              <ForwardOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
        {/* {dataSidebars.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <ListItem button key={index}>
                <ListItemIcon>
                  <DashboardCustomizeOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            )
          })} */}
      </Drawer>
    </Box>
  );
}
