//dashboard
import {
  PersonOutline,
  PersonAddAlt,
  Monitor,
  LocalAtm,
  ArrowUpward,
  ArrowDownward,
  //active account
  MailOutlined,
  PersonOutlineOutlined,
  PhoneIphoneOutlined,
  Apps,
  //add new creator
  ThumbUpOutlined,
  ReportGmailerrorredOutlined,
  PersonOutlineSharp,
  BadgeOutlined,
  RoomOutlined,
  Smartphone,
  CakeOutlined,
  MailOutline,
  Language,
  Transgender,
  FindInPageOutlined,
  ContactPageOutlined,
  RequestPageOutlined,
  LayersOutlined,
  StarBorderOutlined,
  Crop169Outlined,
  ArticleOutlined,
  LocalAtmOutlined,
  GroupsOutlined,
  DateRangeOutlined,
  CircleOutlined,
  AccountBalanceOutlined,
  FacebookOutlined,
  //creator list
  VideoCameraBackOutlined,
  ArrowUpwardOutlined,
  ArrowDownwardOutlined,
  ArrowForwardOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  StarOutline,
  //organization
  Delete,
  //performance live stream
  FilterListOutlined,
  VisibilityOutlined,
  WatchLaterOutlined,
  //get tokens
  CopyAll,
  SupervisedUserCircleOutlined,
  CastConnectedOutlined
} from "@mui/icons-material";

const icons = {
  //dashboard
  PERSON_OUTLINE: PersonOutline,
  PERSON_ADD_ALT: PersonAddAlt,
  MONITOR: Monitor,
  LOCAL_ATM: LocalAtm,
  ARROW_UPWARD: ArrowUpward,
  ARROW_DOWNWARD: ArrowDownward,
  //active account
  MAIL_OUTLINED: MailOutlined,
  PERSON_OUTLINE_OUTLINED: PersonOutlineOutlined,
  PHONE_IPHONE_OUTLINED: PhoneIphoneOutlined,
  APPS: Apps,
  //add new creator
  THUMB_UP_OUTLINED: ThumbUpOutlined,
  REPORT_GMAILERRORRED_OUTLINED: ReportGmailerrorredOutlined,
  PERSON_OUTLINE_SHARP: PersonOutlineSharp,
  BADGE_OUTLINED: BadgeOutlined,
  ROOM_OUTLINED: RoomOutlined,
  SMARTPHONE: Smartphone,
  CAKE_OUTLINED: CakeOutlined,
  MAIL_OUTLINE: MailOutline,
  LANGUAGE: Language,
  TRANSGENDER: Transgender,
  FIND_IN_PAGE_OUTLINED: FindInPageOutlined,
  CONTACT_PAGE_OUTLINED: ContactPageOutlined,
  REQUEST_PAGE_OUTLINED: RequestPageOutlined,
  LAYERS_OUTLINED: LayersOutlined,
  STAR_BORDER_OUTLINED: StarBorderOutlined,
  CROP_169_OUTLINED: Crop169Outlined,
  ARTICLE_OUTLINED: ArticleOutlined,
  LOCAL_ATM_OUTLINED: LocalAtmOutlined,
  GROUP_OUTLINED: GroupsOutlined,
  DATE_RANGE_OUTLINED: DateRangeOutlined,
  CIRCLE_OUTLINED: CircleOutlined,
  ACCOUNT_BALANCE_OUTLINED: AccountBalanceOutlined,
  FACEBOOK_OUTLINED: FacebookOutlined,
  //creator list
  VIDEO_CAMERA_BACK_OUTLINED: VideoCameraBackOutlined,
  ARROW_UPWARD_OUTLINED: ArrowUpwardOutlined,
  ARROW_DOWNWARD_OUTLINED: ArrowDownwardOutlined,
  ARROW_FORWARD_OUTLINED: ArrowForwardOutlined,
  STAR_OUTLINE: StarOutline,
  //organization
  DELETE: Delete,
  //performance live stream
  FILTER_LIST_OUTLINED: FilterListOutlined,
  VISIBILITY_OUTLINED: VisibilityOutlined,
  WATCH_LATER_OUTLINED: WatchLaterOutlined,
  //performance vod
  //get Tokens
  COPY_ALL: CopyAll,
  KEYBOARD_ARROW_LEFT: KeyboardArrowLeft,
  KEYBOARD_ARROW_RIGHT: KeyboardArrowRight,
  VIEW_60S_ICON: SupervisedUserCircleOutlined,
  VIEW_3S_ICON: CastConnectedOutlined
};

export default icons;
