import React from "react";
import { Box } from "@mui/material";
import { Alerts } from "components/alerts";
import Icons from "constants/icons";
import "./styles.scss";
import { numberFormat } from "helpers/utils";

const CreatorSummaryBlock = (props) => {
  const summaryBlockDetail = (title, data, hasPercent = false) => {
    return (
      <>
        <div>{title}</div>
        <div className="content-number">
          <span className="total-number">{numberFormat(data.sum)}</span>
          {hasPercent && (
            <span
              className={
                data.status === 1
                  ? "total-percent--up"
                  : data.status === -1
                  ? "total-percent--down"
                  : "total-percent--as"
              }
            >
              {data.status === 1 ? (
                <Icons.ARROW_UPWARD_OUTLINED
                  sx={{ verticalAlign: "text-bottom" }}
                />
              ) : data.status === -1 ? (
                <Icons.ARROW_DOWNWARD_OUTLINED
                  sx={{ verticalAlign: "text-bottom" }}
                />
              ) : (
                <Icons.ARROW_FORWARD_OUTLINED
                  sx={{ verticalAlign: "text-bottom" }}
                />
              )}
              {data.percent}%
            </span>
          )}
        </div>
      </>
    );
  };

  const { data, error } = props;
  const summary = data?.data;

  if (summary === undefined) {
    return null;
  }
  
  return (
    <Box className="creator-summary-box">
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.PERSON_OUTLINE
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        <Box>{summaryBlockDetail("Total Creator", summary.total_creator)}</Box>
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.PERSON_ADD_ALT
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        <Box>{summaryBlockDetail("New Creator", summary.total_creator_this_month, true)}</Box>
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.STAR_OUTLINE
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        <Box>{summaryBlockDetail("Total Tier With 1.5K Tier", summary.total_tier_1_5k, true)}</Box>
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.STAR_OUTLINE
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        <Box>{summaryBlockDetail("Total Tier With 3K Tier", summary.total_tier_3k, true)}</Box>
      </div>
      {error && <Alerts msg={error.data.error.message} type="error" />}
    </Box>
  );
};

export { CreatorSummaryBlock };
