import { api } from "./api";

export const creatorKpiApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["CreatorKpis"],
  endpoints: (builder) => ({
    getCreatorKpis: builder.query({
      query: () => ({
        url: `creator-kpis`,
      }),
      providesTags: ["CreatorKpis"],
    }),
    getCreatorKpiById: builder.query({
      query: (id) => `creator-kpi/${id}`,
      providesTags: ["CreatorKpis"],
    }),
    addNewCreatorKpi: builder.mutation({
      query: (credentials) => ({
        url: "creator-kpi",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["CreatorKpis"],
    }),
    deleteCreatorKpiByIds: builder.mutation({
      query: (ids) => ({
        url: `creator-kpi/${ids}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CreatorKpis"],
    }),
    getCreatorKpisSearch: builder.mutation({
      query: (prms) => ({
        url: `creator-kpis`,
        params: prms
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCreatorKpisQuery,
  useAddNewCreatorKpiMutation,
  useDeleteCreatorKpiByIdsMutation,
  useGetCreatorKpiByIdQuery,
  useGetCreatorKpisSearchMutation
} = creatorKpiApi;
