import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { selectAccessToken } from '../store/userSlice';

const Layout = ({ children }) => {
    const accessToken = useSelector(selectAccessToken);
    if (accessToken) return <Navigate to="/" replace />;
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                bgcolor: '#1267FC',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
            }}
        >
            <Paper sx={{
                width: 850,
                height: 850,
                borderRadius: '50px',
                mr: '5%',
                boxShadow: '0px 10px 25px 2px rgba(0, 0, 0, 0.4)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {children}
            </Paper>
        </Box>
    );
}

export default Layout;