import { api } from "./api";

export const creatorPerformanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLiveStreamingSummary: builder.query({
      query: (params) => ({
        url: "creator-performance/summary/stream",
        params: params,
      }),
    }),
    getStreamingData: builder.query({
      query: (params) => ({
        url: "creator-performance",
        params: params,
      }),
    }),
    getStreamingDataExport: builder.query({
      query: (params) => ({
        url: "creator-performance-export",
        params: params,
        responseType: 'blob'
      }),
    }),
    getCreatorPerformanceDetail: builder.query({
      query: (params) => {
        const { id } = params;
        let filter = { ...params };
        delete filter.id;
        return {
          url: `creator-performance/${id}`,
          params: filter,
        };
      },
    }),
    getCreatorPerformanceDetailSummary: builder.query({
      query: (params) => {
        const { id, metric } = params;
        let filter = { ...params };
        delete filter.id;
        delete filter.metric;
        return {
          url: `creator-performance/${id}/summary/${metric}`,
          method: "GET",
          params: filter,
        };
      },
    }),
    getVodSummary: builder.query({
      query: (params) => ({
        url: "creator-performance/summary/vod",
        params: params,
      }),
    }),
    getSearchStreamingData: builder.mutation({
      query: (params) => ({
        url: "creator-performance",
        params: params,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetStreamingDataQuery,
  useGetStreamingDataExportQuery,
  useGetLiveStreamingSummaryQuery,
  useGetCreatorPerformanceDetailQuery,
  useGetCreatorPerformanceDetailSummaryQuery,
  useGetVodSummaryQuery,
  useGetSearchStreamingDataMutation,
} = creatorPerformanceApi;
