import { Button } from '@mui/material'
import React from 'react'
import './styles.scss'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  secondaryBtnStyle: {
    background: '#00AFB5',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '18px',
    borderRadius: '10px',
    textTransform: 'none', 
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, #00AFB5, #00AFB5)'
    }
  }
}));

const SecondaryButton = (props) => {
  const classes = useStyles();
  return (
    <Button className={classes.secondaryBtnStyle} {...props}>{props.children}</Button>
  )
}

export default SecondaryButton
