import { api } from "./api";

export const tierApi = api.injectEndpoints({
  refetchOnFocus: true,
  tagTypes: ["Tiers"],
  endpoints: (builder) => ({
    getTierAll: builder.query({
      query: () => ({
          url: `tiers`,
          method: 'GET',
          subscribe: false
      }),
      providesTags: ["Tiers"],
    }),
    getTierById: builder.query({
      query: (id) => ({
          url: `tier/${id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
      providesTags: ["Tiers"],
    }),
    getTierByProgram: builder.query({
      query: (params) => ({
          url: `tiers/${params.program_id}`,
          method: 'GET',
          subscribe: false,
          forceRefetch: true,
      }),
    }),
    addNewTier: builder.mutation({
      query: (credentials) => ({
        url: "tier",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Tiers"],
    }),
    deleteTierByIds: builder.mutation({
      query: (ids) => ({
        url: `tier/${ids}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tiers"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTierAllQuery,
  useGetTierByIdQuery,
  useGetTierByProgramQuery,
  useAddNewTierMutation,
  useDeleteTierByIdsMutation,
} = tierApi;
