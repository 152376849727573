import React from "react";
import { Field, reduxForm } from "redux-form";
import { Box, Button, Checkbox, ListItemText, MenuItem } from "@mui/material";
import {
  TextInputStandard,
  MultipleSelectStandard,
} from "components/InputStandard";
import { useGetProgramAllQuery } from "api/programApi";

let TierForm = (props) => {
  const { handleSubmit, submitting } = props;
  const [checkedValue, setCheckedValue] = React.useState([]);
  const programs = useGetProgramAllQuery();
  
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field component="input" name="id" type="hidden" />
        <Field
          component={MultipleSelectStandard}
          id="program_id"
          name="program_id"
          label="Program"
          items={programs.data?.data}
          loading={programs.isFetching}
          setCheckedValue={setCheckedValue}
          required
        >
          {programs.data?.data.map((program) => (
            <MenuItem key={program.id} value={program.id}>
              <Checkbox checked={checkedValue.indexOf(program.id) > -1} />
              <ListItemText primary={program.name} />
            </MenuItem>
          ))}
        </Field>
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="name"
          name="name"
          label="Name"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="description"
          name="description"
          label="Description"
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, float: "right" },
        }}
      >
        <Button
          variant="contained"
          className="btn-submit-dialog"
          type="submit"
          disabled={submitting}
          xs={{ float: "right" }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

TierForm = reduxForm({
  form: "tierForm",
  enableReinitialize: true,
})(TierForm);

export default TierForm;
