import { Card, CardContent, Grid, CardHeader, Link } from "@mui/material";
import React, { useState } from "react";
import "./styles.scss";
import LinearProgress from "@mui/material/LinearProgress";
import Images from "../constants/images";
import { useSelector } from "react-redux";
import {
  useGetSummaryQuery,
  useGetTargetCreatorQuery,
  useGetCreatorRankQuery,
} from "api/dashboardApi";
import { DashboardSummaryBlock } from "components/dashboardSummaryBlock";
import { DashboardTargetBlock } from "components/dashboardTargetBlock";
import { DashboardCreatorRankBlock } from "components/dashboardCreatorRankBlock";
import { selectUser } from "../store/userSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const currentMonth = moment().startOf("month").format("YYYY-MM-DD");
  const [targetMonth, setTargetMonth] = useState(currentMonth);
  const [metric, setMetric] = useState("stream");
  const [rankMonth, setRankMonth] = useState(currentMonth);
  const [rankType, setRankType] = useState(0);
  const [creatorType, setCreatorType] = useState('L');
  const summary = useGetSummaryQuery();
  const target = useGetTargetCreatorQuery({
    metric: metric,
    period: targetMonth,
  });
  const rank = useGetCreatorRankQuery({
    rankType: rankType,
    period: rankMonth,
    creatorType: creatorType
  });

  const currentUser = useSelector(selectUser);

  const creatorSeeAllClick = () => {
    if(creatorType == 'L')
      navigate("/creator-performance/live-streaming");
    else
      navigate("/creator-performance/vod");
  };

  const creatorAchievementSeeAllClick = () => {
    navigate("/creator-management/creator-list");
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={7} md={7}>
        <Card sx={{ borderRadius: "25px" }}>
          {summary.isLoading && <LinearProgress />}
          <CardHeader
            title={`Welcome back, ${currentUser?.name}`}
            subheader="Glad to see you again! Here is a brief about your creators  performance summary"
            className="dashboard-title"
          />
          <CardContent>
            <DashboardSummaryBlock {...summary} />
          </CardContent>
        </Card>

        <Card sx={{ borderRadius: "25px", mt: "20px" }}>
          {rank.isFetching && <LinearProgress />}
          <CardHeader
            title="Creator Rank"
            subheader="Creator Ranking is sorted based on their performance. This will help you to follow up, review or give performance recommendation"
            className="dashboard-title"
            action={
              <Link href="#" underline="none" className="see-all" onClick={creatorSeeAllClick} >
                See All
              </Link>
            }
          />
          <CardContent className="card-content-target">
            <DashboardCreatorRankBlock
              {...rank}
              rankMonth={rankMonth}
              setRankMonth={setRankMonth}
              rankType={rankType}
              setRankType={setRankType}
              creatorType={creatorType}
              setCreatorType={setCreatorType}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={5} md={5}>
        <Card sx={{ borderRadius: "25px" }}>
          {target.isFetching && <LinearProgress />}
          <CardHeader
            title="Target Creator Achievements"
            subheader="Overal creator achieviement based on their performance."
            className="dashboard-title"
            action={
              <Link href="#" underline="none" className="see-all" onClick={creatorAchievementSeeAllClick}>
                See All
              </Link>
            }
          />
          <CardContent className="card-content-target">
            <DashboardTargetBlock
              {...target}
              targetMonth={targetMonth}
              setTargetMonth={setTargetMonth}
              metric={metric}
              setMetric={setMetric}
            />
          </CardContent>
        </Card>
        <Card sx={{ borderRadius: "25px", mt: "20px" }}>
          <Images.COMING_SOON />
        </Card>
      </Grid>
    </Grid>
  );
}
