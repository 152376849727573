import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { Alerts } from "components/alerts";
import "./styles.scss";

const DropdownIndicator = (order = null) => {
  const styleArrowUpIcon = {
    position: "absolute",
    left: 0,
    top: "-15px",
    color: "#1267fc",
    color: "#C4C4C4",
  };
  const styleArrowDownIcon = {
    position: "absolute",
    left: 0,
    bottom: "-15px",
    color: "#C4C4C4",
  };
  return (
    <div style={{ position: "relative" }}>
      <ArrowDropUpIcon
        style={
          order === "asc"
            ? { ...styleArrowUpIcon, color: "#1267fc" }
            : { ...styleArrowUpIcon }
        }
      />
      <ArrowDropDownIcon
        style={
          order === "desc"
            ? { ...styleArrowDownIcon, color: "#1267fc" }
            : { ...styleArrowDownIcon }
        }
      />
    </div>
  );
};

const BaseTable = (props) => {
  const { tableHeaders, renderBody, serviceApi, filter, isRefetch, setIsRefetch, searchData } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const filterUrl = queryString.parse(location.search);
  let sortObject = [];
  if (filterUrl.sort) {
    sortObject = filterUrl.sort.split(",");
  }
  const [orderBy, setOrderBy] = useState(sortObject[0] ? sortObject[0] : "");
  const [order, setOrder] = useState(sortObject[1] ? sortObject[1] : "asc");
  const [page, setPage] = useState(0);
  const [pageData, setPageData] = useState(null);

  const handleRequestSort = (property) => {
    const orderInField =
      orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(orderInField);
    setOrderBy(property);
    const moreFilter = { sort: `${property},${orderInField}` };
    filterChange(moreFilter);
  };

  useEffect(() => {
    filterChange(filterUrl);
  }, [location.search]);

  useEffect(() => {
    if(isRefetch)
    {
      refetch();
      if(setIsRefetch)
      setIsRefetch(false);
    }
  }, [isRefetch]);

  const filterChange = (moreFilter) => {
    let filter = queryString.parse(location.search);

    if (moreFilter) {
      filter = { ...filter, ...moreFilter };
    }
    if(filter.page) {
      setPage(parseInt(filter.page) - 1)
    }
    if (!filter?.sort) {
      setOrder("asc");
      setOrderBy("");
    }
    const url = queryString.stringify(filter);
    navigate(`${location.pathname}?${url}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const moreFilter = { page: newPage + 1 };
    filterChange(moreFilter);
  };

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const handleChangeRowsPerPage = (event) => {
     setRowsPerPage(event.target.value);
     const moreFilter = { page: 1, page_size: event.target.value };
     filterChange(moreFilter);
   };

  let { id } = useParams();

  let otherFilter = null;
  if (props.game !== undefined) {
    otherFilter = {
      metric: props.metric,
      month: props.month,
      game: props.game,
    };
  }
  let params = {
    ...filterUrl,
    ...otherFilter,
  };

  if (filter && Object.keys(filter).length > 0) {
    params = { ...filter };
  }

  if (id) {
    params = { ...params, id };
  }
  const {
    data,
    error = undefined,
    isFetching,
    refetch
  } = serviceApi(params, {
    refetchOnMountOrArgChange: true,
    cacheTime:0
  });
  useEffect(() => {
    setPageData(searchData);
  }, [searchData]);
  
  useEffect(() => {
    setPageData(data);
  }, [data]);
  return (
    <div className="table-content">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((item, index) => {
                return (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    sortDirection="desc"
                    key={index}
                  >
                    {item.field ? (
                      <TableSortLabel
                        active={orderBy === item.field}
                        onClick={() => handleRequestSort(item.field)}
                        IconComponent={() =>
                          orderBy === item.field
                            ? DropdownIndicator(order)
                            : DropdownIndicator()
                        }
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{renderBody(pageData?.data, pageData?.meta)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPage={20}
        component="div"
        count={pageData?.meta.total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        showFirstButton
        showLastButton
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={isFetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && <Alerts msg={error.data?.error?.message} type="error" />}
    </div>
  );
};

export default BaseTable;
