import * as axios from "axios";

axios.defaults.baseURL = window.env.API_URL; //live
// axios.defaults.baseURL = 'http://localhost/csp_be/public/crm/v1'; //local
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0'
};
axios.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  error => Promise.reject(error)
);

export default axios;