import React, { useState } from 'react';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import './styles.scss'
import { Link } from 'react-router-dom';

const theme = createTheme();

const errorStyle = {
  left: '10px',
  fontSize: '11px',
  color: 'red'
}

export default function SignUp() {


  const submit = (event) => {

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          className='sign-up-container'
        >
          <Typography className='typography' component="h1" variant="h5" >
            Sign Up to CSP
          </Typography>
          <Box component="form" sx={{ mt: 1, width: '100%' }}>
            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={6} sx={{ display: 'block', mb: '22px' }}>
                <Box sx={{ display: 'flex' }}>
                  <InputAdornment
                    className='input-icon'
                  >
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                  <OutlinedInput
                    className="input-content-left"
                    name="firstName"
                    fullWidth
                    placeholder="First Name"
                    autoFocus
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <OutlinedInput
                  className="input-content"
                  name="lastName"
                  fullWidth
                  placeholder="Last Name"
                  autoFocus
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <PhoneIphoneOutlinedIcon />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="phoneNumber"
                fullWidth
                placeholder="Phone Number"
                autoFocus
              />
            </Box>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <MailOutlinedIcon />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="email"
                fullWidth
                placeholder="Email"
                autoFocus
              />
            </Box>
            <Box sx={{ display: 'flex', mb: '22px' }}>
              <InputAdornment
                className="input-icon"
              >
                <LanguageOutlinedIcon />
              </InputAdornment>
              <OutlinedInput
                className="input-content"
                name="company"
                fullWidth
                placeholder="Company"
                autoFocus
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="submit"
            // disabled={isLoading}
            >
              <span>Sign up</span>
            </Button>
            <Box className="remind">
              Already have an account? &nbsp;
              <Link to="/sign-in">Sign In</Link>
            </Box>

          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}