import { createTheme } from '@mui/system';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import './index.scss';
import RouteList from './routes';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

let persistor = persistStore(store)
ReactDOM.render(
  <React.StrictMode theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouteList />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
