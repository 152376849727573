import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  TableRow,
  TableCell,
  LinearProgress,
  Stack,
  Select,
  MenuItem,
  Link,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import Icons from "constants/icons";
import BaseTable from "components/TableCreator";
import { BootstrapTooltip } from "components/tooltip";
import {
  useGetCreatorPerformanceDetailQuery,
  useGetCreatorPerformanceDetailSummaryQuery,
} from "api/creatorPerformanceApi";
import "./styles.scss";
import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getPeriodsInMonths, numberFormat } from "helpers/utils";
import moment from "moment";
import queryString from "query-string";
import SecondaryButton from "components/Button";
import axios from "api/apiAxios";
import { Alerts } from "components/alerts";

const months = getPeriodsInMonths();
const currentMonth = moment().startOf("month").format("YYYY-MM-DD");

const tableHeaders = [
  { label: "#", field: null },
  { label: "GMT+8", field: "published_at" },
  { label: "PST", field: "published_at_8" },
  { label: "Video ID", field: "video_id" },
  { label: "Video Duration", field: "video_duration" },
  { label: "Game Name", field: "game_name" },
  { label: "Watch Hours", field: "watch_hours" },
  { label: "Product Efficiency (PE)", field: null },
];

const listDuration = [{'id':-1, 'name':'All'}, {'id':101, 'name': 'Above 59 minutes'}];

let params = {};

const LiveStreamingDetail = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);

  const [month, setMonth] = useState(filter.period || currentMonth);
  const handleSelectMonth = (event) => {
    setMonth(event.target.value);
    filter.period = event.target.value;
    const url = queryString.stringify(filter);
    navigate(`${location.pathname}?${url}`);
  };

  const [duration, setDuration] = useState(filter?.duration || -1);

  const handleSelectDuration = (event) => {
    setDuration(event.target.value);
    filter.duration = event.target.value;
    const url = queryString.stringify(filter);
    navigate(`${location.pathname}?${url}`);
  };

  useEffect(() => {
    params = queryString.parse(location.search);
    params.metric = "stream";
    if (!params.period) {
      params.period = currentMonth;
      const url = queryString.stringify(params);
      navigate(`${location.pathname}?${url}`);
    }
  }, [location.search]);

  const { id } = useParams();
  const summary = useGetCreatorPerformanceDetailSummaryQuery(
    { id, metric: "stream", period: month, duration: duration },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const renderTableBody = (data = []) => {
    if (data?.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={tableHeaders.length}></TableCell>
        </TableRow>
      );
    const page = filter.page ? parseInt(filter.page) : 1;
    return data?.map((item, index) => {
      return (
        <TableRow hover tabIndex={-1} key={index}>
          <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
          <TableCell>{item.published_at}</TableCell>
          <TableCell>{item.published_at_8}</TableCell>
          <TableCell>
            <BootstrapTooltip title="Click to open video" placement="top">
              <Link
                href={
                  "https://www.facebook.com/" +
                  item.page_name +
                  "/videos/" +
                  item.video_id
                }
                underline="none"
                target="_blank"
              >
                {item.video_id}
              </Link>
            </BootstrapTooltip>
          </TableCell>
          <TableCell>{item.video_duration}</TableCell>
          <TableCell>{item.game_name}</TableCell>
          <TableCell>{numberFormat(item.watch_hours, 2)}</TableCell>
          <TableCell>{numberFormat(item.pe, 2)}</TableCell>
        </TableRow>
      );
    });
  };

  const [exportLoading, setExportLoading] = useState(false);
  const exportData = () => {
    setExportLoading(true);
    axios({
      url: "/creator-performance-detail-export/" + id, //your url
      method: "GET",
      params: { metric: "stream", period: month, duration: duration },
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `Live_Streaming_Detail_${month}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoading(false);
      });
  };

  const [message, setmessage] = useState('');
  const GetVideoFunction = () => {
    setmessage('');
    setExportLoading(true);
    setTimeout(function() {
        setmessage('Process is in queue, please refresh after a while');
        setExportLoading(false);
    }, 2000);
  }
  return (
    <>
      <Grid container>
        <Stack spacing={2} style={{ width: "100%" }}>
          <Grid item md={12}>
            <Card
              variant="outlined"
              className="summary"
              sx={{ mb: 1, mt: 2, borderRadius: "25px" }}
            >
              {summary.isLoading && <LinearProgress />}
              <CardContent>
                <div className="summary-header">
                  <span className="summary-title">Monthly Progress</span>
                </div>
                <Box style={{ display: "flex" }}>
                  <div variant="outlined" className="summary-paper">
                    <Box className="summary-paper--icon">
                      <Icons.VIDEO_CAMERA_BACK_OUTLINED
                        sx={{
                          fontSize: 50,
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
                          borderRadius: "15px",
                          padding: "5px",
                        }}
                      />
                    </Box>
                    <Box>
                      <div>Number of Live</div>
                      <div className="content-number">
                        <span className="total-number">
                          {numberFormat(summary?.data?.data.streams)}
                        </span>
                        <span className="total-percent">
                          /{numberFormat(summary?.data?.data.kpi_streams)}
                        </span>
                      </div>
                    </Box>
                  </div>
                  <div variant="outlined" className="summary-paper">
                    <Box className="summary-paper--icon">
                      <Icons.VIDEO_CAMERA_BACK_OUTLINED
                        sx={{
                          fontSize: 50,
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
                          borderRadius: "15px",
                          padding: "5px",
                        }}
                      />
                    </Box>
                    <Box>
                      <div>Watch Hour Target</div>
                      <div className="content-number">
                        <span className="total-number">
                          {numberFormat(summary?.data?.data.watch_hour, 2)}
                        </span>
                        <span className="total-percent">
                          /{numberFormat(summary?.data?.data.kpi_stream_watch_hours, 2)}
                        </span>
                      </div>
                    </Box>
                  </div>
                  <div variant="outlined" className="summary-paper">
                    <Box className="summary-paper--icon">
                      <Icons.VIDEO_CAMERA_BACK_OUTLINED
                        sx={{
                          fontSize: 50,
                          background:
                            "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
                          borderRadius: "15px",
                          padding: "5px",
                        }}
                      />
                    </Box>
                    <Box>
                      <div>Duration Target</div>
                      <div className="content-number">
                        <span className="total-number">
                          {numberFormat(summary?.data?.data.duration, 2)}
                        </span>
                        <span className="total-percent">
                          /{numberFormat(summary?.data?.data.kpi_stream_broadcast_hours, 2)}
                        </span>
                      </div>
                    </Box>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Stack>

        {/* Data table */}
        <Grid md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
              <div style={{ display: "flex" }} className="cp-table-content">
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      padding: "auto 15px",
                      marginLeft: "5px",
                    }}
                  >
                    Video List
                  </span>
                </div>
                <div>
                  <Select
                      className="summary-month"
                      value={duration}
                      onChange={handleSelectDuration}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {listDuration?.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <Select
                    className="summary-month"
                    value={month}
                    onChange={handleSelectMonth}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {months.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {/*<div style={{ marginRight: "5px" }}>
                  <SecondaryButton onClick={() => GetVideoFunction()}>
                    Get FB Videos
                  </SecondaryButton>
                    </div>*/}
                <div style={{ marginRight: "5px" }}>
                  <SecondaryButton onClick={() => exportData()}>
                    Export Data
                  </SecondaryButton>
                </div>
              </div>
              <BaseTable
                filter={params}
                tableHeaders={tableHeaders}
                renderBody={(data, meta) => renderTableBody(data, meta)}
                serviceApi={useGetCreatorPerformanceDetailQuery}
              />
            </Card>
          </Box>
        </Grid>
      </Grid>
      {message && <Alerts msg={message} type="success" />}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={exportLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LiveStreamingDetail;
