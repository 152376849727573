import React from "react";
import {
  Grid,
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Alerts } from "components/alerts";
import Icons from "constants/icons";
import "./styles.scss";
import { numberFormat } from "helpers/utils";

const DashboardSummaryBlock = (props) => {
  const { data, error } = props;
  const summary = data?.data;

  if (summary === undefined) {
    return null;
  }
  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={3}>
        <ListItem>
          <ListItemAvatar>
            <Avatar className="f-ico">
              <Icons.PERSON_OUTLINE className="col-ico" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Total Creator" />
        </ListItem>
        <Box className="val-summary-up">{numberFormat(summary.total_creator.sum, 2)}</Box>
      </Grid>
      <Grid item xs={3}>
        <ListItem>
          <ListItemAvatar>
            <Avatar className="f-ico">
              <Icons.PERSON_ADD_ALT className="col-ico" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="New Creator" />
        </ListItem>
        <Box className="val-summary-as">
          {numberFormat(summary.total_creator_this_month.sum, 2)}
          <Box
            className={
              summary.total_creator_this_month.status === 1
                ? "sub-sum-as total-percent--up"
                : summary.total_creator_this_month.status === -1
                ? "sub-sum-as total-percent--down"
                : "sub-sum-as total-percent--as"
            }
          >
            {summary.total_creator_this_month.status === 1 ? (
              <Icons.ARROW_UPWARD_OUTLINED
                sx={{ verticalAlign: "text-bottom" }}
              />
            ) : summary.total_creator_this_month.status === -1 ? (
              <Icons.ARROW_DOWNWARD_OUTLINED
                sx={{ verticalAlign: "text-bottom" }}
              />
            ) : (
              <Icons.ARROW_FORWARD_OUTLINED
                sx={{ verticalAlign: "text-bottom" }}
              />
            )}
            {summary.total_creator_this_month.percent}%
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <ListItem>
          <ListItemAvatar>
            <Avatar className="f-ico">
              <Icons.MONITOR className="col-ico" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Avg.Watch Hour" />
        </ListItem>
        <Box className="val-summary-down">
          {numberFormat(summary.avg_whr, 2)}
          {/* <Box className="sub-sum-down">
            <Icons.ARROW_DOWNWARD />
            10%
          </Box> */}
        </Box>
      </Grid>
      <Grid item xs={3} sx={{ marginTop: "10px" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar className="f-ico">
              <Icons.LOCAL_ATM className="col-ico" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="PE Rate" />
        </ListItem>
        <Box className="val-summary-up">
          {numberFormat(summary.pe_rate, 2)}
          {/* <Box className="sub-sum-up">
            <Icons.ARROW_UPWARD />
            10%
          </Box> */}
        </Box>
      </Grid>
      {error && <Alerts msg={error.data.error.message} type="error" />}
    </Grid>
  );
};

export { DashboardSummaryBlock };
