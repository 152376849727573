import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useGetContractByIdQuery } from "api/contractApi";
import ContractForm from "components/organization/contractForm";

const ContractPopup = (props) => {
  const { open, setOpen, onSubmit, id, actionLoading } = props;
  const contract = useGetContractByIdQuery(id);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} className="dialog-body">
        {(contract?.isFetching || actionLoading) && <LinearProgress />}
        <DialogTitle className="dialog-title">
          {id > 0 ? "Update Contract" : "Add Contract"}
        </DialogTitle>
        <DialogContent>
          <ContractForm
            onSubmit={onSubmit}
            initialValues={
              contract?.data?.data.length === 0 ? null : contract?.data?.data
            }
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContractPopup;
