import React from "react";
import { Field, reduxForm } from "redux-form";
import { Box, Button, MenuItem } from "@mui/material";
import {
  TextInputStandard,
  SelectStandard,
  DatePickerStandard,
} from "components/InputStandard";
import { useGetCreatorByTenantQuery } from "api/creatorApi";

let KPIIndexForm = (props) => { 
  const { handleSubmit, submitting } = props;
  const creators = useGetCreatorByTenantQuery();
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "52ch" },
        }}
      >
        <Field component="input" name="id" type="hidden" />
        <Field
          component={SelectStandard}
          id="creator_id"
          name="creator_id"
          label="Creator"
          loading={creators.isFetching}
          required
        >
          {creators.data?.data.map((creator) => (
            <MenuItem value={creator.id}>
              {creator.name + (creator.email == null || creator.email == '' ? '' : " - " + creator.email)}
            </MenuItem>
          ))}
        </Field>
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="streams"
          name="streams"
          label="Streams"
          type="number"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          required
        />
        <Field
          component={TextInputStandard}
          id="stream_watch_hours"
          name="stream_watch_hours"
          label="Stream Watch Hours"
          type="number"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="stream_broadcast_hours"
          name="stream_broadcast_hours"
          label="Stream Broadcast Hours"
          type="number"
          required
        />
        <Field
          component={TextInputStandard}
          id="videos"
          name="videos"
          label="Videos"
          type="number"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        <Field
          component={TextInputStandard}
          id="video_watch_hours"
          name="video_watch_hours"
          label="Video Watch Hours"
          type="number"
          required
        />
        <Field
          component={TextInputStandard}
          id="video_broadcast_hours"
          name="video_broadcast_hours"
          label="Video Broadcast Hours"
          type="number"
          required
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        <Field
          component={DatePickerStandard}
          id="start_date"
          name="start_date"
          label="Start Date"
          inputFormat="YYYY-MM-DD"
          required
        />
        <Field
          component={DatePickerStandard}
          id="end_date"
          name="end_date"
          label="End Date"
        />
      </Box>
      <Box
        sx={{
          "& > :not(style)": { m: 1, float: "right" },
        }}
      >
        <Button
          variant="contained"
          className="btn-submit-dialog"
          type="submit"
          disabled={submitting}
          xs={{ float: "right" }}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

KPIIndexForm = reduxForm({
  form: "kpiIndexForm",
  enableReinitialize: true,
})(KPIIndexForm);

export default KPIIndexForm;
