import {
    Box,
    Button,
    Card,
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableHead,
  } from "@mui/material";
  import React, { useState } from "react";
  import { TableRow, TableCell } from "@mui/material";
  import "./importProcess.scss";
  import SearchComponent from "components/SearchComponent";
  import { useImportNewCreatorMutation } from "api/creatorApi";
  import { ProgressBar } from "components/progressBar";
  
  const tableHeaders = [
    { label: "#", field: null },
    { label: "Page Name", field: "name" },
    { label: "Facebook page id", field: "fb_page_id" },
    { label: "tier id", field: "tier_id" },
    { label: "Contract id", field: "contract_id" },
    { label: "WHR live", field: "live_whr" },
    { label: "BH live", field: "live_bh" },
    { label: "No of live", field: "live_video" },
    { label: "No of vod", field: "vod_video" },
    { label: "WHR vod", field: "vod_whr" },
    { label: "BH vod", field: "vod_bh" },
    { label: "Active", field: "active" },
    { label: "Creator Type", field: "creator_type_id" },
    { label: "Region", field: "region" },
    { label: "Import status" }
  ];
  
  const ImportProcess = (props) => {
    const {
        handleSwitchStep,
        basicInfo,
        setBasicInfo
    } = props;
    const [isFinish, setIsFinish] = useState(false);
    const [progress, setProgress] = useState(0);
    const [importNewCreatorMutation, inportResp] = useImportNewCreatorMutation();
    const renderTableBody = (data = []) => {
      if (!data || data.length === 0)
        return (
          <TableRow>
            <TableCell colSpan={tableHeaders.length}></TableCell>
          </TableRow>
        );
  
      return data?.map((item, index) => {
        return (
          <TableRow
            hover
            className="hover-row"
            tabIndex={-1}
            key={index}
            //onClick={() => showDetail(item.creator_id)}
          >
            <TableCell>{index + 1}</TableCell>
            <TableCell>{item.page_name}</TableCell>
            <TableCell>{item.page_id}</TableCell>
            <TableCell>{item.tier_id}</TableCell>
            <TableCell>{item.contract_id}</TableCell>
            <TableCell>{item.live_whr}</TableCell>
            <TableCell>{item.live_bh}</TableCell>
            <TableCell>{item.live_video}</TableCell>
            <TableCell>{item.vod_video}</TableCell>
            <TableCell>{item.vod_whr}</TableCell>
            <TableCell>{item.vod_bh}</TableCell>
            <TableCell>{item.active}</TableCell>
            <TableCell>{item.creator_type_id}</TableCell>
            <TableCell>{item.region}</TableCell>
            <TableCell>{item.import_status}</TableCell>
          </TableRow>
        );
      });
    };
  
    const [searchText, setSearchText] = useState(null);
    const onSearchChange = (value) =>{
      setSearchText(value.target.value);
    }
    const handSearchClick = () =>{

    }

    const ImportCreatorAction = async () =>
    {
      if(!basicInfo || !basicInfo?.data)
      {
        return;
      }
      const creatorCount = basicInfo.data.length;
      let newData = basicInfo.data;
      for(let i = 0; i < creatorCount; i++)
      {
        let stutus = '';
        try {
          if(basicInfo.data[i].page_id && basicInfo.data[i].page_name)
          {
            const result = await importNewCreatorMutation(basicInfo.data[i]).unwrap();
            if (result.success) {
              stutus='success';
            }
            else
            {
              stutus='failure';
            }
          }
        } catch (error) {
          stutus='failure';
        }
        newData = newData.map(creator=>{
          if(creator.page_id == basicInfo.data[i].page_id)
          {
            return {...creator, import_status:stutus};
          }
          return creator;
        });
        setBasicInfo({...basicInfo, ...{data:newData}});
        setProgress((i + 1) * 100/ creatorCount);
      }
      setIsFinish(true);
    }
  
    return (
      <>
        <Grid md={12} spacing={5}>
            <Box sx={{ width: "100%" }}>
              <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
                <div style={{ display: "flex" }} className="cp-table-content">
                  <div style={{ flex: 1 }}>
                    <span
                        style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        marginLeft: "19px",
                          }}
                        >
                          Creator import list
                      </span>
                      <SearchComponent handleClick={()=>handSearchClick()} onTextChange={(e)=>onSearchChange(e)} style={{ float: "right", height: "35px" }} />
                  </div>
                </div>
                <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                        {tableHeaders.map((item, index) => {
                            return (
                            <TableCell
                                style={{ fontWeight: "bold" }}
                                sortDirection="desc"
                                key={index}
                            >
                                {item.label}
                            </TableCell>
                            );
                        })}
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableBody(basicInfo.data)}</TableBody>
                </Table>
                </TableContainer>
              </Card>
            </Box>
        </Grid>
        {progress > 0 && <Box sx={{ width: '100%' }}>
          <ProgressBar value={progress} />
        </Box>}
        <Box className="navigate-box">
            <Button
                color="inherit"
                disabled
                onClick={()=>handleSwitchStep(-1)}
                sx={{ mr: 1 }}
            >
                Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {!isFinish && <Button disabled={!basicInfo.path} onClick={()=>ImportCreatorAction()}>
                Start
            </Button>}
            {isFinish && <Button onClick={()=>handleSwitchStep(1)}>
                      Finish
            </Button>}
        </Box>
      </>
    );
  };
  
  export default ImportProcess;
  