import moment from 'moment'

const required = (value) => (value != null && `${value}`.length > 0 ? undefined : 'This field is required')
const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ? 'Email is not valid' : undefined)
const formatDate = (value) => (value && /^\d{4}[./-]\d{2}[./-]\d{2}$/i.test(value) ? undefined : 'Date is not valid')

export default {
    required,
    email,
    formatDate
}