import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Select,
  MenuItem,
  TableCell,
  TableRow,
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Icons from "constants/icons";
import moment from "moment";
import BaseTable from "components/TableCreator";
import queryString from "query-string";
import {
  useGetStreamingDataQuery,
  useGetVodSummaryQuery,
  useGetSearchStreamingDataMutation
} from "api/creatorPerformanceApi";
import { useGetTierByProgramQuery } from "api/tierApi";
import { useGetCreatorTypeByProgramQuery } from "api/creatorTypeApi";
import "./styles.scss";
import { MultipleSelectChip, SingleSelect, DateRangePickerEx } from "components/filterSelect";
import { getPeriodsInMonths, numberFormat } from "helpers/utils";
import { Alerts } from "components/alerts";
import SecondaryButton from "components/Button";
import axios from "api/apiAxios";
import SearchComponent from "components/SearchComponent";
import { useGetCountryAllQuery } from "api/countryApi";

const months = getPeriodsInMonths();
const currentMonth = moment().startOf("month").format("YYYY-MM-DD");

const tableHeaders = [
  { label: "#", field: null },
  { label: "Page Name", field: "page_name" },
  //{ label: "PageID", field: "fb_page_id" },
  //{ label: "Tier", field: "tier" },
  //{ label: "Type", field: "creator_type" },
  //{ label: "Contract Type", field: "contract_type" },
  { label: "Upload Progress", field: "upload_progress" },
  { label: "BH progress", field: "duration_progress" },
  { label: "WHR progress", field: "watch_hour_progress" },
  { label: "Total Upload", field: "uploaded" },
  { label: "Total WHR", field: "watch_hour" },
  { label: "Duration", field: "duration" },
  { label: "Avg Daily WHR", field: "avg_whr" },
  { label: "3-Second Views", field: "total_view_3s" },
  { label: "1-Minute Views", field: "total_view_60s" },
  { label: "Reach Users", field: "reach_users" },
  { label: "Engagments", field: "engagements" },
];

const renderSummaryPercent = (label, number, percent, isLoading) => {
  return (
    <Box>
      <div>{label}</div>
      <div className="content-number">
        {isLoading && <div>Loading...</div>}
        {!isLoading && (
          <>
            <span className="total-number">{numberFormat(number, 2)}</span>
            {/* <span className={`total-percent ${percent < 0 ? "negative" : ""}`}>
              {percent < 0 ? (
                <Icons.ARROW_DOWNWARD_OUTLINED
                  sx={{ verticalAlign: "text-bottom" }}
                />
              ) : (
                <Icons.ARROW_UPWARD_OUTLINED
                  sx={{ verticalAlign: "text-bottom" }}
                />
              )}
              {percent}%
            </span> */}
          </>
        )}
      </div>
    </Box>
  );
};

const SummaryBlock = (props) => {
  const { data, error, isLoading } = props;
  const summary = data?.data;

  if (summary === undefined) {
    return null;
  }

  return (
    <Box style={{ display: "flex" }}>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.VIDEO_CAMERA_BACK_OUTLINED
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        {renderSummaryPercent("Videos", summary.uploaded, 1, isLoading)}
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.VISIBILITY_OUTLINED
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        {renderSummaryPercent("Watch Hours", summary.watch_hour, 1, isLoading)}
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.VIEW_3S_ICON
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        {renderSummaryPercent("3-Second Views", summary.view_3s, 1, isLoading)}
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.VIEW_60S_ICON
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        {renderSummaryPercent("1-Minute Views", summary.view_60s, 1, isLoading)}
      </div>
      <div variant="outlined" className="summary-paper">
        <Box className="summary-paper--icon">
          <Icons.WATCH_LATER_OUTLINED
            sx={{
              fontSize: 50,
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #1267FC",
              borderRadius: "15px",
              padding: "5px",
            }}
          />
        </Box>
        {renderSummaryPercent("Duration", summary.duration, 1, isLoading)}
      </div>
      {error && <Alerts msg={error.data.error.message} type="error" />}
    </Box>
  );
};

let params = {};

const VoD = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let filter = queryString.parse(location.search);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const countries = useGetCountryAllQuery();

  useEffect(() => {
    params = queryString.parse(location.search);
    if (!params.metric) {
      params.metric = "vod";
    }
    if (!params.period) {
      params.period = currentMonth;
    }
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  }, [location.search]);

  const [month, setMonth] = useState(filter.period || currentMonth);
  const summary = useGetVodSummaryQuery(
    { 
      period: filter.period || currentMonth,
      creator_type: params.creator_type_id,
      tier_id: params.tier_id
   },
    { refetchOnMountOrArgChange: true }
  );
  const tiers = useGetTierByProgramQuery({ program_id: 1 });
  const creatorTypes = useGetCreatorTypeByProgramQuery({ program_id: 1 });

  const renderTableBody = (data = [], meta = null) => {
    if (data.length === 0)
      return (
        <TableRow>
          <TableCell colSpan={tableHeaders.length}></TableCell>
        </TableRow>
      );

    return data.map((item, index) => {
      const number = (meta.current_page - 1) * meta.per_page;
      return (
        <TableRow
          hover
          className="hover-row"
          tabIndex={-1}
          key={index}
          onClick={() => showDetail(item.creator_id)}
        >
          <TableCell>{number + index + 1}</TableCell>
          <TableCell>{item.page_name}</TableCell>
          {/*<TableCell>{item.fb_page_id}</TableCell>
          <TableCell>{item.tier}</TableCell>
          <TableCell>{item.creator_type}</TableCell>
      <TableCell>{item.contract_type}</TableCell>*/}
          <TableCell>{item.upload_progress}%</TableCell>
          <TableCell>{item.duration_progress}%</TableCell>
          <TableCell>{item.watch_hour_progress}%</TableCell>
          <TableCell>{numberFormat(item.total_upload)}</TableCell>
          <TableCell>{numberFormat(item.total_whr, 2)}</TableCell>
          <TableCell>{numberFormat(item.total_duration, 2)}</TableCell>
          <TableCell>{numberFormat(item.avg_whr, 2)}</TableCell>
          <TableCell>{numberFormat(item.total_view_3s )}</TableCell>
          <TableCell>{numberFormat(item.total_view_60s )}</TableCell>
          <TableCell>{numberFormat(item.reach_users )}</TableCell>
          <TableCell>{numberFormat(item.engagements )}</TableCell>
        </TableRow>
      );
    });
  };

  const handleSelectMonth = (event) => {
    setMonth(event.target.value);
  };

  const showDetail = (id) => {
    navigate(`/creator-performance/vod/${id}`);
  };

  const [exportLoading, setExportLoading] = useState(false);
  const exportData = () => {
    setExportLoading(true);
    const filterUrl = {
      ...filter,
      creator_type_id: filterTypeChange,
      tier_id: filterTierChange.join(","),
      period: month,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    axios({
      url: "/creator-performance-export", //your url
      method: "GET",
      params: filterUrl,
      responseType: "blob", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `VOD_${month}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setExportLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoading(false);
      });
  };

  const [filterTierChange, setFilterTierChange] = React.useState(
    filter?.tier_id
      ? filter?.tier_id.split(",").map(function (item) {
          return parseInt(item, 10);
        })
      : []
  );
  const [filterTypeChange, setFilterTypeChange] = React.useState(
    filter?.creator_type_id !== undefined ? filter?.creator_type_id : ""
  );

  const handleFilterTierChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterTierChange(typeof value === "string" ? value.split(",") : value);
  };

  const handleFilterTypeChange = (event) => {
    setFilterTypeChange(event.target.value);
  };
  const handleFilterClear = () => {
    setFilterTierChange([]);
    setFilterTypeChange("");
    setMonth(currentMonth);
    setCountry("");
    setStartDate(null);
    setEndDate(null);
    params = { metric: params.metric, period: currentMonth };
    const url = queryString.stringify(params);
    navigate(`${location.pathname}?${url}`);
  };
  const handleFilterSubmit = () => {
    const filterUrl = {
      ...filter,
      creator_type_id: filterTypeChange,
      tier_id: filterTierChange.join(","),
      period: month,
      startDate: startDate,
      endDate: endDate,
      country_id: country,
    };
    Object.keys(filterUrl).forEach((item) => {
      if (!filterUrl[item]) {
        delete filterUrl[item];
      }
    });
    const url = queryString.stringify(filterUrl);
    navigate(`${location.pathname}?${url}`);
  };

  const [searchText, setSearchText] = useState(null);
  const onSearchChange = (value) =>{
    setSearchText(value.target.value);
  }
  const [getSearchStreamingData, getSearchStreamingDataResp] = useGetSearchStreamingDataMutation();
  const [creatorSearch, setCreatorSearch] = useState(null);

  const handSearchClick = async () =>{
    setExportLoading(true);
    const dataSearch = await getSearchStreamingData({
      ...params, searchText
    }).unwrap();
    setCreatorSearch(dataSearch);
    setExportLoading(false);
  }

  const [country, setCountry] = useState(
    filter?.country_id !== undefined ? filter?.country_id : ""
  );

  const handleSelectCountry = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Card variant="outlined" sx={{ mb: 2, mt: 2, borderRadius: "25px" }}>
            <CardContent style={{ padding: 10 }}>
              <div>
              <Grid container spacing={3}>
                <Grid item xs={6} md={8}>
                <FormControl className="form-select">
                  <InputLabel id="filterMonth">Select Month</InputLabel>
                  <Select
                    labelId="filterMonth"
                    label="Select Month"
                    value={month}
                    onChange={handleSelectMonth}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="h-select"
                  >
                    {months.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/*<FormControl className="form-select">
                        <DateRangePickerEx
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                        />
                    </FormControl>*/}
                    <SingleSelect
                      loading={countries?.isFetching}
                      data={countries?.data?.data}
                      labelName="Select Country"
                      labelId="lb_filter_country"
                      id="filter_country"
                      inputId="filter_country_input"
                      defaultValue={country}
                      handleChange={handleSelectCountry}
                  />
                </Grid>
              </Grid>
              </div>
              <Grid container>
                <Grid item md={6}>
                  <MultipleSelectChip
                    loading={tiers.isFetching}
                    data={tiers.data?.data}
                    labelName="Select Tier"
                    labelId="lb_filter_tier"
                    id="filter_tier"
                    inputId="filter_tier_input"
                    defaultValue={filterTierChange}
                    handleChange={handleFilterTierChange}
                  />
                  <SingleSelect
                    loading={creatorTypes?.isFetching}
                    data={creatorTypes?.data?.data}
                    labelName="Select Type"
                    labelId="lb_filter_type"
                    id="filter_type"
                    inputId="filter_type_input"
                    defaultValue={filterTypeChange}
                    handleChange={handleFilterTypeChange}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControl sx={{ m: 1, float: "right" }}>
                    <Button
                      variant="contained"
                      className="btn-clear-filter"
                      onClick={handleFilterClear}
                    >
                      Clear
                    </Button>
                  </FormControl>
                  <FormControl sx={{ m: 1, float: "right" }}>
                    <Button
                      variant="contained"
                      className="btn-submit-filter"
                      onClick={handleFilterSubmit}
                    >
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12}>
          <Card
            variant="outlined"
            className="summary"
            sx={{ borderRadius: "25px" }}
          >
            {summary.isLoading && <LinearProgress />}
            <CardContent>
              <div className="summary-header">
                <span className="summary-title">Performance Summary</span>
              </div>
              <SummaryBlock {...summary} />
            </CardContent>
          </Card>
        </Grid>

        {/* Data table */}
        <Grid md={12} spacing={5}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
              <div style={{ display: "flex" }} className="cp-table-content">
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      padding: "auto 15px",
                      marginLeft: "5px",
                    }}
                  >
                    Creator Data
                  </span>
                </div>
                <div style={{ marginRight: "5px", display: "flex" }}>
                  <SearchComponent handleClick={()=>handSearchClick()} onTextChange={(e)=>onSearchChange(e)} />
                  <SecondaryButton onClick={() => exportData()}>
                    Export Data
                  </SecondaryButton>
                </div>
              </div>
              <BaseTable
                tableHeaders={tableHeaders}
                renderBody={(data, meta) => renderTableBody(data, meta)}
                filter={params}
                serviceApi={useGetStreamingDataQuery}
                searchData={creatorSearch}
              />
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer - 1,
          position: "absolute",
          opacity: 0.5,
        }}
        open={exportLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default VoD;
