import axiosGlobal from "axios";
import axios from "./apiAxios";

let url = 'https://graph.facebook.com/v13.0/me/accounts?access_token='; //live
// axios.defaults.baseURL = 'http://localhost/csp_be/public/crm/v1'; //local

export const PageProcess = {
    async savePageToken(token){
      const pageData = await axios({
        url: "/updatePageToken", //your url
        method: "POST",
        data: {short_token:token},
        timeout:1000 * 60 * 30
      });
    }
}
