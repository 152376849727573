import React, { useEffect } from "react";
import { Card, Grid, Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./styles.scss";
import KPIIndexData from "components/organization/kpiIndex";
import TypeData from "components/organization/type";
import TierData from "components/organization/tier";
import ContractData from "components/organization/contract";

const checkTabIndex = () => {
  switch (window.location.pathname.split("/").pop()) {
    case "kpi-index":
      return "1";
    case "type":
      return "2";
    case "tier":
      return "3";
    case "contract":
      return "4";
    default:
      return "1";
  }
};
const Organization = () => {
  const [value, setValue] = React.useState(checkTabIndex());

  useEffect(() => {
    setValue(checkTabIndex());
  }, [checkTabIndex()]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyle = {
    fontWeight: 600,
    textAlign: "center",
    flex: 1,
    maxWidth: "none",
    color: "#1267fc",
  };
  return (
    <Grid container className="creator-list">
      {/* Data table */}
      <Grid md={12} spacing={5}>
        <Box sx={{ width: "100%" }}>
          <Card sx={{ mb: 2, mt: 2, p: 2, borderRadius: "15px" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} centered className="tab-list">
                  <Tab
                    sx={tabStyle}
                    label="KPI Index"
                    value="1"
                    className="tab-index"
                  />
                  <Tab
                    sx={tabStyle}
                    label="Type"
                    value="2"
                    className="tab-index"
                  />
                  <Tab
                    sx={tabStyle}
                    label="Tier"
                    value="3"
                    className="tab-index"
                  />
                  <Tab
                    sx={tabStyle}
                    label="Contract"
                    value="4"
                    className="tab-index"
                  />
                </TabList>
              </Box>
              <TabPanel value="1" className="tab-panel">
                <KPIIndexData />
              </TabPanel>
              <TabPanel value="2" className="tab-panel">
                <TypeData />
              </TabPanel>
              <TabPanel value="3" className="tab-panel">
                <TierData />
              </TabPanel>
              <TabPanel value="4" className="tab-panel">
                <ContractData />
              </TabPanel>
            </TabContext>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Organization;
